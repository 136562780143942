import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

export default function MessageDetailsModal({ open, setOpen, message }) {
  const handleClose = () => {
    setOpen(false);
  };

  const ORDER = [
    "inputCost",
    "outputCost",
    "imageGeneration",
    "imageProcessing",
    "audioToText",
    "audioTranscription",
    "fileProcessing",
    "urlProcessing",
    "sendMessage",
    "speechCost",
  ];

  const LABELS = {
    inputCost: "Input Cost",
    outputCost: "Output Cost",
    audioToText: "Audio processing",
    audioTranscription: "Audio transcription",
    speechCost: "Read message",
    fileProcessing: "File processing",
    sendMessage: "Message fee",
    imageProcessing: "Image processing",
    imageGeneration: "Image generation",
    urlProcessing: "URL processing",
  };

  const renderExtras = () => {
    if (!message.costInfo?.speechCost) return null;
    return (
      <>
        <Typography textAlign="center" fontFamily={"'Anton', sans-serif"}>
          Extras
        </Typography>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell align="left">
                <Typography fontWeight="bold">Read Message</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography>{message.costInfo.speechCost}</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </>
    );
  };

  let total = 0;
  if (message.costInfo) {
    Object.keys(message.costInfo)
      .filter((key) => key !== "total")
      .forEach((key) => (total += message.costInfo[key]));
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Message Details</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          {message.chargeData && (
            <>
              <Typography textAlign="center" fontFamily={"'Anton', sans-serif"}>
                Token information
              </Typography>
              <Table size="small">
                <TableBody>
                  {message.chargeData?.inputTokens && (
                    <TableRow>
                      <TableCell align="left">
                        <Typography fontWeight="bold">Input Tokens</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography>
                          {message.chargeData.inputTokens}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {message.chargeData?.outputTokens && (
                    <TableRow>
                      <TableCell align="left">
                        <Typography fontWeight="bold">Output Tokens</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography>
                          {message.chargeData.outputTokens}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </>
          )}
          <Typography textAlign="center" fontFamily={"'Anton', sans-serif"}>
            Cost information
          </Typography>
          {message.costInfo && (
            <Table size="small">
              <TableBody>
                {ORDER.filter((key) =>
                  message.costInfo.hasOwnProperty(key)
                ).map((key) => (
                  <TableRow key={key}>
                    <TableCell align="left">
                      <Typography fontWeight="bold">{LABELS[key]}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography>
                        {message.costInfo[key].toFixed(6)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell align="left">
                    <Typography fontWeight="bold">Total</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>{total.toFixed(6)}</Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
