import { Stack } from "@mui/material";
import React from "react";
import MessageContainer from "components/chat/MessageContainer";
import MarkdownBlock from "components/react-lib/markdown-code/MarkdownBlock";
import { colors } from "colors";
import CopyButton from "components/react-lib/generic-ui/CopyButton";

export default function AudioMessage({ message, isLive, inContext, noMenu }) {

  const formatTextBlocks = (messageText) => {
    let count = 0;
    // Replace every second occurrence of "```" with "```text"
    const replaceIndexes = [];
    for (let i = 0; i < messageText.length - 3; i++) {
      if (messageText.substring(i, i + 3) === "```") {
        count += 1;
        if (count % 2 === 1) {
          // It's the start of a code block
          if (i + 3 < messageText.length && /\s/.test(messageText[i + 3])) {
            // Replace
            replaceIndexes.push(i);
            i += 3;
          }
        }
      }
    }
    let result = "";
    for (let i = 0; i < messageText.length; i++) {
      if (replaceIndexes.includes(i)) {
        result += "```text";
        i += 3;
      } else {
        result += messageText[i];
      }
    }
    return result;
  };

  const renderBottomContent = () => {
    if (isLive) return null;
    return <CopyButton color="white" />;
  };
  
  return (
    <MessageContainer
      message={message}
      isLive={isLive}
      bottomContent={renderBottomContent()}
      inContext={inContext}
      noMenu={noMenu}
    >
      {/* <audio 
        style={{
          width: "100%",
          paddingTop: "10px",
        }}
        src={message.audioUrl} 
        controls
      /> */}
      {message.transcription && (
        <MarkdownBlock 
          markdownContent={formatTextBlocks(message.transcription)}
          canCopyCode={true}
          rawCopyButtonColor={colors.white}
          style={{
            color: colors.white,
            lineHeight: 1.5,
            fontFamily: '"Geist", sans-serif',
            overflowX: "auto",
          }}
        />
      )}
      {message.image && (
        <Stack
          alignItems="center"
          justifyContent="center"
        >
          <img
            src={message.image}
            alt=""
            style={{
              width: "200px",
              borderRadius: "10px",
            }}
          />
        </Stack>
      )}
    </MessageContainer>
  );
}
