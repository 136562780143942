import { Avatar, CircularProgress } from "@mui/material";
import { colors } from "colors";
import React from "react";
import { useSelector } from "react-redux";

export default function BotAvatar({botId, ...props}) {
  
  const botState = useSelector((state) => state.bot);
  
  if(!botState.idMap || !botState.idMap[botId]) {
    return null;
  }

  const bot = botState.idMap[botId];
  
  return (
    <Avatar
      {...props}
      src={`https://api.dicebear.com/7.x/bottts/svg?seed=${bot.avatar}`}
    />
  );

}
