import React, { useEffect, useState } from "react";
import TextAttachment from "./TextAttachment";
import URLSelector from "./URLSelector";
import FileUploader from "components/react-lib/generic-ui/FileUploader";
import ImageUploader from "components/react-lib/generic-ui/ImageUploader";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import Selector from "components/react-lib/generic-ui/Selector";
import ActionButton from "components/react-lib/generic-ui/ActionButton";
import AlertMessage from "components/react-lib/generic-ui/AlertMessage";
import { addDoc, collection } from "firebase/firestore";
import { db } from "firebaseApp";
import { COLLECTIONS } from "collections";
import { useDispatch, useSelector } from "react-redux";
import { URL_TYPE, FILE_TYPE, IMAGE_TYPE, TEXT_TYPE } from "utils/attachments";
import { shareActions } from "store/share";

export default function CreateAttachmentFromShareModal({
  open,
}) {

  const dispatch = useDispatch();

  const shareState = useSelector((state) => state.share);

  const [attachment, setAttachment] = useState(shareState.attachment);
  const [processing, setProcessing] = useState(false);

  const handleClose = () => {
    dispatch(shareActions.reset());
  };

  const canAttach = () => {
    switch (shareState.type) {
      case URL_TYPE.id:
        return Boolean(attachment?.content);
      case FILE_TYPE.id:
        return Boolean(attachment?.filename);
      case IMAGE_TYPE.id:
        return Boolean(attachment?.imageUrl);
      case TEXT_TYPE.id:
        return Boolean(attachment?.content);
    }
    return false;
  };

  const handleFileUploaded = (file) => {
    setAttachment(file);
  };

  const handleImageUploaded = (image) => {
    setAttachment(image);
  };

  const renderForm = () => {
    switch (shareState.type) {
      case TEXT_TYPE.id:
        return <TextAttachment value={attachment} setValue={setAttachment} />;
      case URL_TYPE.id:
        return <URLSelector value={attachment} setValue={setAttachment} />;
      case FILE_TYPE.id:
        return <FileUploader successCallback={handleFileUploaded} />;
      case IMAGE_TYPE.id:
        return (
          <ImageUploader
            successCallback={handleImageUploaded}
            bucketName="inputImages"
            preview={true}
          />
        );
    }
    return null;
  };

  const handleAttach = async () => {
    try {
      setProcessing(true);
      const attachmentData = {
        ...attachment,
        type: shareState.type,
        createdAt: new Date().getTime(),
      };
      await addDoc(
        collection(db, COLLECTIONS.attachments),
        attachmentData
      );
      handleClose();
    } catch (error) {
      console.log(error);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Attach</DialogTitle>
      <DialogContent>
        <Stack mt={1} spacing={1}>
          {renderForm()}
          {attachment && shareState.type === FILE_TYPE.id && (
            <AlertMessage severity="success">
              File {attachment.filename} uploaded
            </AlertMessage>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <ButtonGroup fullWidth>
          <Button variant="contained" color="pink" onClick={handleClose}>
            Cancel
          </Button>
          <ActionButton
            data-testid="finalize-attach-button"
            variant="contained"
            color="black"
            disabled={!canAttach()}
            onClick={handleAttach}
            processing={processing}
          >
            Create
          </ActionButton>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}
