import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import CopyUid from "components/groups/CopyUid";
import DeleteAccountButton from "components/react-lib/auth/DeleteAccountButton";
import Loader from "components/react-lib/generic-ui/Loader";
import EditDocumentForm from "components/react-lib/generic-ui/firestore/EditDocumentForm";
import ReduxModal from "components/react-lib/generic-ui/modals/ReduxModal";
import { useAuth } from "context/react-lib/auth/AuthProvider";
import { doc } from "firebase/firestore";
import { db } from "firebaseApp";
import React from "react";

export default function EditProfileModal() {

  const auth = useAuth();

  const renderContent = () => {
    if (!auth.currentUser) {
      return <Loader message="Loading profile data" />;
    }
    return (
      <Stack
        spacing={1}
      >
        <CopyUid />
        <EditDocumentForm
          documentRef={doc(db, "userPublicProfiles", auth.currentUser.uid)}
          schemaBuildData={{
            bucket: "userAvatars",
          }}
        />
        <DeleteAccountButton
          color="secondary"
          variant="contained"
        />
      </Stack>
    );
  };

  return (
    <ReduxModal
      name="profile"
      title="Edit Profile"
      renderContent={renderContent}
    />
  );
}
