import {
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useDispatch, useSelector } from "react-redux";
import { addDoc, collection } from "firebase/firestore";
import { db } from "firebaseApp";
import { useAuth } from "context/react-lib/auth/AuthProvider";
import { createConversation } from "utils/conversations";
import AttachFileModal, { TEXT, URL, FILE, IMAGE } from "./AttachFileModal";
import { botActions } from "store/bot";


export default function AttachFileButton({
  supportedTypes = [TEXT, URL, FILE, IMAGE],
  buttonProps = {},
}) {
  const dispatch = useDispatch();

  const auth = useAuth();
  const botState = useSelector((state) => state.bot);
  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleAttach = async (attachmentType, attachment, setProcessing) => {
    try {
      setProcessing(true);
      let conversation = botState.activeConversation;
      if(!conversation) {
        conversation = await createConversation(
          botState.current,
          auth.currentUser.uid,
          dispatch
        ); 
      }
      await addDoc(collection(db, "attachments"), {
        ...attachment,
        type: attachmentType,
        botId: botState.current,
        conversationId: conversation.id,
        createdAt: new Date().getTime(),
      });
      handleClose();
    } catch (error) {
      console.log(error);
    } finally {
      setProcessing(false);
    }
  };

 
  const handleOpen = async () => {
    try {
      setModalOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <AttachFileModal
        open={modalOpen}
        setOpen={setModalOpen}
        processAttachment={handleAttach}
        supportedTypes={supportedTypes}
      />
      <IconButton
        onClick={handleOpen}
        data-testid="attach-file-button"
        {...buttonProps}
      >
        <AttachFileIcon />
      </IconButton>
    </>
  );
}
