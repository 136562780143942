import React, { useEffect, useRef, useState } from "react";
import { Alert, Stack, Button, Paper, Box, Typography } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { auth } from "firebaseApp";
import {
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { useDispatch } from "react-redux";
import { userActions } from "../store/user";
import { useSelector } from "react-redux";
import Lottie from "lottie-react";
import backgroundAnimation from "lottie/background.json";
import { colors } from "colors";
import EmailPasswordAuthForm from "components/react-lib/auth/EmailPasswordAuthForm";
import Logo from "components/svg/Logo";
import { fonts } from "fonts";
import useIsMobile from "hooks/react-lib/generic-ui/useIsMobile";
import GoogleLoginButton from "components/react-lib/auth/GoogleLoginButton";

export default function Auth() {

  const isMobile = useIsMobile();

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const googleAuthProvider = new GoogleAuthProvider();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const installPromptEventRef = useRef();

  onAuthStateChanged(auth, (user) => {
    if (user) {
      dispatch(
        userActions.login({
          uid: user.uid,
        })
      );
    } else {
      dispatch(userActions.logout());
    }
  });

  const loginWithGoogle = async () => {
    setError();
    setIsLoading(true);
    try {
      await signInWithPopup(auth, googleAuthProvider);
    } catch (error) {
      console.log(error);
      setError("Could not login it. Try again later.");
    }
    setIsLoading(false);
  };

  const logout = async () => {
    await signOut(auth);
  };

  const renderButton = () => {
    if (user.uid) {
      return (
        <Button
          color="primary"
          variant="contained"
          startIcon={<ExitToAppIcon />}
          onClick={logout}
          disabled={isLoading}
        >
          Logout
        </Button>
      );
    }
    return (
      <Button
        data-testid="google-login-button"
        variant="contained"
        color="green"
        startIcon={<GoogleIcon />}
        onClick={loginWithGoogle}
        disabled={isLoading}
      >
        Login with Google
      </Button>
    );
  };

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      installPromptEventRef.current = e;
    });  
  }, []);

  
  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      sx={{
        height: "100vh",
        width: "100vw",
        backgroundColor: colors.black,
        position: "relative",
      }}
      justifyContent="center"
      alignItems="center"
      spacing={isMobile ? 2 : 5}
    >
      {/* <Lottie
        animationData={backgroundAnimation}
        loop={true}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
        }}
      /> */}
      {error && <Alert severity="error">{error}</Alert>}
      <Stack
        alignItems="center"
        justifyContent="center"
      >
        <Typography color={colors.white} fontFamily={fonts.anton} fontSize="2rem">Cheap-GPT</Typography>
        <Logo
          style={{
            width: "100px",
          }}
        />
        <Typography color={colors.white} variant="caption" fontSize="1rem">Your subscription-free door to AI</Typography>
       
      </Stack>

      <Paper
        sx={{
          zIndex: 1000,
          width: "250px",
        }}
      >
        <Stack p={2} spacing={2}>
          <EmailPasswordAuthForm />

          <GoogleLoginButton 
            loginProps={{
              color: "green",
              variant: "contained",
            }}
          />

          <Button
            variant="contained"
            onClick={() => installPromptEventRef.current.prompt()}
          >
            Install
          </Button>
          {/* {renderButton()} */}
        </Stack>
      </Paper>
    </Stack>
  );
}
