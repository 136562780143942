import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AlertMessage from "components/react-lib/generic-ui/AlertMessage";
import Loader from "components/react-lib/generic-ui/Loader";
import ReduxModal from "components/react-lib/generic-ui/modals/ReduxModal";
import Selector from "components/react-lib/generic-ui/Selector";
import HeaderCell from "components/table/HeaderCell";
import { useGlobalRefs } from "context/react-lib/generic-ui/GlobalRefContext";
import { collection, doc, query } from "firebase/firestore";
import { db } from "firebaseApp";
import React, { useState } from "react";
import { useCollectionOnce, useDocument, useDocumentData, useDocumentDataOnce, useDocumentOnce } from "react-firebase-hooks/firestore";
import { useDispatch, useSelector } from "react-redux";
import { snapshotToArray } from "utils/react-lib/generic-ui/firebaseUtils";
import ModelPrice from "./ModelPrice";

export default function PricingModal() {

  const [pricing, loadingPricing, pricingError] = useDocumentDataOnce(
    doc(db, "pricing", "latest")
  );

  const [modelsSnap, loadingModels, modelsError] = useCollectionOnce(
    collection(db, "models")
  );

  const [modelId, setModelId] = useState("");

  const renderContent = () => {
    if (loadingModels || loadingPricing) {
      return <Loader message="Loading pricing information" />;
    }
    if (modelsError || pricingError) {
      console.log(modelsError);
      console.log(pricingError);
      return (
        <AlertMessage severity="error">
          Couldn't load pricing information. Try again later.
        </AlertMessage>
      );
    }
    const models = snapshotToArray(modelsSnap);
    return (
      <Stack
        mt={1}
        spacing={2}
      >
        <Selector
          label="Model"
          value={modelId}
          setValue={setModelId}
          options={models}
          valueField="id"
          labelField="name"
          allowEmpty={true}
        />
        {modelId && (
          <ModelPrice
            pricing={pricing}
            model={models.filter((model) => model.id === modelId)[0]}
          />
        )}
      </Stack>
    );
  };

  return (
    <ReduxModal
      name="pricing"
      title="Pricing"
      fullWidth
      maxWidth="sm"
      renderContent={renderContent}
    />
  );
}
