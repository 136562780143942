// @lib-generic-ui
import { Avatar, CircularProgress } from "@mui/material";
import { useAuth } from "context/react-lib/auth/AuthProvider";
import { doc } from "firebase/firestore";
import { db } from "firebaseApp";
import React from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";

export default function UserAvatar(
  {
    loader=<CircularProgress size={24} />, 
    noImageAvatar=<Avatar>U</Avatar>,
  }
) {
  const auth = useAuth();

  const [userPublicProfile, loading, error] = useDocumentData(
    auth.currentUser
      ? doc(db, "userPublicProfiles", auth.currentUser.uid)
      : null
  );

  if (error) {
    console.log(error);
  }

  if (loading) {
    return loader;
  }

  if (userPublicProfile && userPublicProfile.avatarUrl) {
    return <Avatar src={userPublicProfile.avatarUrl} />;
  }

  return noImageAvatar;
}
