import { COLLECTIONS } from "collections";
import { addDoc, collection } from "firebase/firestore";
import { db } from "firebaseApp";
import { editorActions } from "store/editor";

export async function createDocument(content, setProcessing, uid, dispatch) {
  try {
    setProcessing(true);
    const documentData = {
      uid,
      createdAt: new Date().getTime(),
      name: "New Document",
      content,
    };
    const documentRef = await addDoc(
      collection(
        db,
        COLLECTIONS.mdDocuments,
      ),
      documentData
    );
    const document = {
      id: documentRef.id,
      ...documentData,
    };
    dispatch(editorActions.setActiveDocument(document));
  } catch (error) {
    console.log(error);
  } finally {
    setProcessing(false);
  }
}