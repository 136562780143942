import { Stack, Typography } from "@mui/material";
import React from "react";
import GenerateAudioButton from "./GenerateAudioButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { colors } from "colors";

export default function MessageControls({ message, inContext, processing }) {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row">
        <GenerateAudioButton message={message} processing={processing} />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={0.5}
      >
        <CheckCircleIcon
          color="white"
          sx={{
            width: "10px",
            opacity: !processing && inContext ? 1 : 0,
          }}
        />
        <Typography
          variant="caption"
          fontFamily="Anton"
          fontSize="8px"
          sx={{
            color: colors.white,
            opacity: !processing && inContext ? 1 : 0,
          }}
        >
          In context
        </Typography>
      </Stack>
    </Stack>
  );
}
