import { userPublicProfilesSchema } from "schemas/user";
import { markdownDocumentSchema } from "schemas/documents";
import { supportRequestSchema } from "schemas/supportRequests";
import { conversationSchema } from "schemas/conversations";

export const schemas = {
  ...userPublicProfilesSchema,
  ...markdownDocumentSchema,
  ...supportRequestSchema,
  ...conversationSchema,
};
