import { EXTERNAL_ID_FIELD, STRING_FIELD, STRING_OPTIONS, TIMESTAMP_FIELD } from "utils/react-lib/generic-ui/firestoreFields";

export const supportRequestSchema =  {
  
  "supportRequests/{id}": (buildData) => ({
    _fieldOrder: ["name", "content"],
    message: STRING_FIELD("Message", {
      multiline: true,
      maxRows: 3,
    }),
    type: STRING_OPTIONS("Type", [
      {"label": "Bug", "value": "bug"},
      {"label": "Feature Request", "value": "feature"},
      {"label": "Suggestion", "value": "suggestion"},
      {"label": "Billing Issue", "value": "billing"},
    ]),
    createdAt: TIMESTAMP_FIELD("Created At"),
    uid: EXTERNAL_ID_FIELD("User Id", buildData),
  }),

}