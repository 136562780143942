import { Stack } from "@mui/material";
import React from "react";
import MessageContainer from "components/chat/MessageContainer";
import MarkdownBlock from "components/react-lib/markdown-code/MarkdownBlock";
import { colors } from "colors";
import CopyButton from "components/react-lib/generic-ui/CopyButton";

export default function TextMessage({ message, isLive, inContext, noMenu }) {

  
  const renderBottomContent = () => {
    if (isLive) return null;
    return <CopyButton color="white" />;
  };

  return (
    <MessageContainer
      key={message.id}
      message={message}
      isLive={isLive}
      bottomContent={renderBottomContent()}
      inContext={inContext}
      noMenu={noMenu}
    >
      <MarkdownBlock 
        markdownContent={message.content}
        canCopyCode={true}
        rawTextColor={colors.white}
        rawCopyButtonColor={colors.white}
        style={{
          color: colors.white,
          lineHeight: 1.5,
          fontFamily: '"Geist", sans-serif',
          overflowX: "auto",
        }}
      />
      {message.image && (
        <Stack
          alignItems="center"
          justifyContent="center"
        >
          <img
            src={message.image}
            alt=""
            style={{
              width: "200px",
              borderRadius: "10px",
            }}
          />
        </Stack>
      )}
    </MessageContainer>
  );
}
