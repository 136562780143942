// @lib-stripe-credit-payments
import { Stack, Typography } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { db, functions } from "firebaseApp";
import React, { useEffect, useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import CreditsIndicator from "./CreditsIndicator";
import { httpsCallable } from "firebase/functions";
import useIsMobile from "hooks/react-lib/generic-ui/useIsMobile";

export default function ChargeAccountDisplay({
  uid,
  nameProps = {},
  amountProps = {},
  containerProps = {},
  negativeCreditsColor = "",
}) {
  const isMobile = useIsMobile();

  const [publicProfile, loading, error] = useDocumentData(
    doc(db, "userPublicProfiles", uid)
  );
  const [chargeAccountId, setChargeAccountId] = useState();
  const [loadingAccountData, setLoadingAccountData] = useState(false);
  const [name, setName] = useState("");

  const checkBalance = async () => {
    try {
      await httpsCallable(
        functions,
        "checkUnpaidPayments"
      )({ creditId: chargeAccountId });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (chargeAccountId) {
      checkBalance();
    }
  }, [chargeAccountId]);

  useEffect(() => {
    if (publicProfile) {
      setChargeAccountId(publicProfile.defaultChargeAccount);
    }
  }, [publicProfile]);

  const loadAccountData = async () => {
    try {
      setLoadingAccountData(true);
      let group;
      const isUserAccount = chargeAccountId === uid;
      if (!isUserAccount) {
        group = await getDoc(doc(db, "userGroups", chargeAccountId));
      }
      setName(
        group ? `Group account: ${group.data().name}` : "Personal account"
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingAccountData(false);
    }
  };

  useEffect(() => {
    if (chargeAccountId) {
      loadAccountData();
    }
  }, [chargeAccountId]);

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      {...containerProps}
    >
      {chargeAccountId && (
        <CreditsIndicator
          uid={uid}
          chargeAccountId={chargeAccountId}
          textProps={amountProps}
          currencySymbol="$"
          negativeCreditsColor={negativeCreditsColor}
        />
      )}
      {name && (
        <Typography {...nameProps} fontSize={isMobile ? 10 : 12}>
          {name}
        </Typography>
      )}
    </Stack>
  );
}
