import { Box } from "@mui/material";
import React, { useState } from "react";
import { serializeChildren } from "utils/markdown";
import ContentEditorButton from "./ContentEditorButton";

export default function MdList({ children, updateParagraph }) {

  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        padding: "8px",
        backgroundColor: isHovered ? "rgba(0,0,255,0.1)" : "transparent",
        transition: "background-color 0.3s ease",
        marginBottom: "16px",
        position: "relative",
      }}
    >
      {isHovered && (
        <ContentEditorButton
          paragraphContent={serializeChildren(children).trim()}
          updateParagraph={updateParagraph}
        />
      )}
      {children}
    </Box>
  );
}
