import { IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import UserAvatar from "components/react-lib/generic-ui/UserAvatar";
import { colors } from "colors";
import MessageMenu from "./MessageMenu";
import BotAvatar from "components/bot/BotAvatar";
import MessageAudio from "./MessageAudio";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import GenerateAudioButton from "./GenerateAudioButton";
import MessageControls from "./MessageControls";

export default function MessageContainer({
  message,
  children,
  isLive,
  inContext,
  noMenu,
}) {
  // This variable is used to update the UI immediatily
  const [processingAudio, setProcessingAudio] = useState(
    Boolean(message.processingAudio)
  );

  const chatState = useSelector((state) => state.chat);
  const processing = Boolean(chatState.botProcessing[message.botId]);

  const liveBotId = useSelector((state) => state.chat.liveBotId);
  const isAi = message.role === "assistant";

  const renderAvatar = () => {
    if (isLive) {
      return <BotAvatar botId={liveBotId} />;
    }
    if (isAi) {
      return <BotAvatar botId={message.botId} />;
    }
    return <UserAvatar />;
  };

  return (
    <div
      data-testclass="chat-message-container"
      style={{
        backgroundColor:
          message.role === "assistant" ? colors.black : colors.drakGray,
        zIndex: 1,
        paddingTop: 20,
        paddingBottom: 10,
        paddingLeft: 16,
        paddingRight: 16,
        borderRadius: 8,
        position: "relative",
        width: "100%",
        boxSizing: "border-box",
        maxWidth: "748px",
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        {renderAvatar()}
        {!noMenu && (
          <MessageMenu
            message={message}
            setProcessingAudio={setProcessingAudio}
          />
        )}
      </Stack>
      {children}
      <MessageAudio processingAudio={processingAudio} message={message} />
      <MessageControls message={message} inContext={inContext} processing={processing} />
    </div>
  );
}
