// @lib-generic-ui
import { useState, useEffect } from "react";

function useElementSize(ref) {
  
  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (!ref.current) return;
    const observerCallback = (entries) => {
      if (!Array.isArray(entries) || !entries.length) return;
      const entry = entries[0];
      setSize({
        width: entry.contentRect.width,
        height: entry.contentRect.height,
      });
    };
    const resizeObserver = new ResizeObserver(observerCallback);
    resizeObserver.observe(ref.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [ref]);

  return size;
}

export default useElementSize;
