// @lib-generic-ui
import React, { useState, useEffect } from "react";
import { TextField, Box, Stack } from "@mui/material";
import { PDFDocument } from "pdf-lib";
import Loader from "./Loader";
import LabelledOutline from "./LabelledOutline";

export default function PdfPageRangeSelector({ pdfFile, onPageRangeSelected }) {
  const [pageCount, setPageCount] = useState(null);
  const [startPage, setStartPage] = useState(1);
  const [endPage, setEndPage] = useState(null);

  // Load the PDF to determine the page count
  useEffect(() => {
    const loadPdf = async () => {
      if (pdfFile) {
        try {
          let pdfBytes;
          if (typeof pdfFile === "string") {
            // If pdfFile is a URL, fetch the file
            const response = await fetch(pdfFile);
            pdfBytes = await response.arrayBuffer();
          } else {
            // Assume pdfFile is a File object
            pdfBytes = await pdfFile.arrayBuffer();
          }

          const pdfDoc = await PDFDocument.load(pdfBytes);
          const numPages = pdfDoc.getPageCount();
          setPageCount(numPages);
          setStartPage(1);
          setEndPage(numPages); // Default end page to the last page
        } catch (error) {
          console.error("Failed to load PDF:", error);
        }
      }
    };
    loadPdf();
  }, [pdfFile]);

  useEffect(() => {
    if (onPageRangeSelected) {
      onPageRangeSelected({
        startPage,
        endPage,
      });
    }
  }, [startPage, endPage]);

  const updateStart = (event) => {
    const newPageStart = parseInt(event.target.value, 10) || 1;
    setStartPage(newPageStart);
  };

  const updateEnd = (event) => {
    const newPageEnd = parseInt(event.target.value, 10) || pageCount;
    setEndPage(newPageEnd);
  };

  return (
    <Box>
      {pageCount ? (
        <LabelledOutline label={"Page range"}>
          <Stack direction="row" spacing={1} alignItems="center">
            <TextField
              fullWidth
              label="Start Page"
              type="number"
              value={startPage}
              onChange={updateStart}
              inputProps={{ min: 1, max: pageCount }}
              variant="outlined"
              size="small"
            />
            <TextField
              fullWidth
              label="End Page"
              type="number"
              value={endPage}
              onChange={updateEnd}
              inputProps={{ min: 1, max: pageCount }}
              variant="outlined"
              size="small"
            />
          </Stack>
        </LabelledOutline>
      ) : (
        <Loader message="Loading" />
      )}
    </Box>
  );
}
