import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { addDoc, collection, deleteDoc, doc } from "firebase/firestore";
import { db } from "firebaseApp";
import { colors } from "colors";
import ConfirmModal from "components/react-lib/generic-ui/ConfirmModal";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { COLLECTIONS } from "collections";
import { editorActions } from "store/editor";
import AttachFileModal, { URL, FILE, IMAGE, TEXT } from "components/attachments/AttachFileModal";
import { useAuth } from "context/react-lib/auth/AuthProvider";
import EditDocumentMenuItem from "components/react-lib/generic-ui/firestore/EditDocumentMenuItem";

export default function DocumentMenu({ 
  previous,
  next,
  document,
  color = colors.white,
}) {

  const auth = useAuth();
  const uid = auth.currentUser.uid;

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleting, setDeleting] = React.useState(false);
  const [attachModalOpen, setAttachModalOpen] = React.useState(false);

  const open = Boolean(anchorEl);

  const activeDocument = useSelector((state) => state.editor.activeDocument);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    console.log("CLOSE DOC MENU")
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    try {
      setDeleting(true);
      const docRef = doc(
        db,
        COLLECTIONS.mdDocuments,
        document.id,
      );
      await deleteDoc(docRef);
      handleClose();
      if(document.id === activeDocument.id) {
        if(previous) {
          dispatch(editorActions.setActiveDocument(previous));
        } else if(next) {
          dispatch(editorActions.setActiveDocument(next));        
        } else {
          dispatch(editorActions.setActiveDocument(null));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDeleting(false);
    }
  };

  const processAttachment = async (attachmentType, attachment, setProcessing) => {
    console.log("process attachments");
    try {
      setProcessing(true);
      await addDoc(collection(db, COLLECTIONS.documentAttachments), {
        ...attachment,
        uid,
        type: attachmentType,
        documentId: document.id,
        createdAt: new Date().getTime(),
      });
      handleClose();
    } catch (error) {
      console.log(error);
    } finally {
      setProcessing(false);
    }
  }

  const handleAttachClick = () => {
    setAttachModalOpen(true);
    handleClose();
  }

  return (
    <div>
      <AttachFileModal
        open={attachModalOpen}
        setOpen={setAttachModalOpen}
        processAttachment={processAttachment}
        supportedTypes={[TEXT, URL, FILE, IMAGE]}
      />
      <ConfirmModal
        open={deleting}
        setOpen={setDeleting}
        title="Confirm"
        message="Are you sure to delete this document?"
        confirmAction={handleDelete}
        cancelAction={handleClose}
      />
      <IconButton onClick={handleClick} color={color}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleAttachClick}>
          <ListItemIcon>
            <AttachFileIcon color="black" fontSize="small" />
          </ListItemIcon>
          <ListItemText color="black">Attach</ListItemText>
        </MenuItem>
        <EditDocumentMenuItem
          label="Rename"
          documentRef={doc(db, COLLECTIONS.mdDocuments, document.id)}
          iconProps={{
            color: "black",
          }}
          fieldsToEdit={["name"]}
          closeMenu={handleClose}
        />
        <MenuItem onClick={() => setDeleting(true)}>
          <ListItemIcon>
            <DeleteIcon color="black" fontSize="small" />
          </ListItemIcon>
          <ListItemText color="black">Delete</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}
