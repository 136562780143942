import "./App.css";
import ChatView from "./views/ChatView";
import AuthView from "./views/AuthView";
import { Route, Routes } from "react-router-dom";
import PaymentRedirectView from "views/PaymentRedirectView";
import { useAuth } from "context/react-lib/auth/AuthProvider";
import BotLoader from "components/bot/BotLoader";
import TestView from "views/TestView";
import ToolsView from "views/ToolsView";
import ShareListener from "components/share/ShareListener";
import MarkdownEditorView from "views/MarkdownEditorView";
import ToolLoader from "components/tools/ToolLoader";
import { GlobalRefProvider } from "context/react-lib/generic-ui/GlobalRefContext";
import PopupMessage from "components/react-lib/generic-ui/PopupMessage";

function App() {

  const auth = useAuth();

  if (!auth.currentUser) {
    return <AuthView />;
  }

  return (
    <GlobalRefProvider>
      <PopupMessage />
      <BotLoader />
      <ToolLoader />
      <ShareListener />
      <Routes>
        <Route path="/" element={<ChatView />} />
        <Route path="/payment/:status" element={<PaymentRedirectView />} />
        <Route path="/test" element={<TestView />} />
        <Route path="/tools" element={<ToolsView />} />
        <Route path="/mdEditor" element={<MarkdownEditorView />} />
        <Route path="/saved" element={<MarkdownEditorView />} />
      </Routes>
    </GlobalRefProvider>
  );
}

export default App;
