import { Stack } from "@mui/material";
import { COLLECTIONS } from "collections";
import ActionButton from "components/react-lib/generic-ui/ActionButton";
import { useAuth } from "context/react-lib/auth/AuthProvider";
import { addDoc, collection } from "firebase/firestore";
import { db } from "firebaseApp";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { editorActions } from "store/editor";
import { createDocument } from "utils/editor";

export default function CreateMDDocumentButton({ onCreate, ...props }) {

  const auth = useAuth();
  const dispatch = useDispatch();
  const uid = auth.currentUser?.uid;

  const [processing, setProcessing] = useState(false);

  const handleCreateMDDocument = async () => {
    await createDocument("", setProcessing, uid, dispatch);
  };

  return (
    <Stack>
      <ActionButton
        fullWidth
        variant="outlined"
        processing={processing}
        onClick={handleCreateMDDocument}
        size="small"
        data-testid="new-md-document-button"
        {...props}
      >
        New Document
      </ActionButton>
    </Stack>
  );
}
