// @lib-generic-ui
import { Avatar, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import React from "react";
import {
  AVATAR_TYPE,
  EXTERNAL_ID_TYPE,
  IMAGE_TYPE,
  INT_TYPE,
  NUMBER_TYPE,
  STRING_OPTIONS_TYPE,
  STRING_TYPE,
  TIMESTAMP_TYPE,
} from "utils/react-lib/generic-ui/firestoreTypes";
import CollectionSelector from "components/react-lib/generic-ui/CollectionSelector";
import DateTimePicker from "components/react-lib/generic-ui/DateTimePicker";
import ImageSelectorButton from "../ImageSelectorButton";
import ImageUploader from "../ImageUploader";

export default function DefaultFieldEditor({
  value,
  setValue,
  field,
  fieldSchema,
  processing,
  textFieldProps,
}) {
  const type = fieldSchema.type;

  if (type === STRING_TYPE) {
    return (
      <TextField
        {...textFieldProps}
        {...fieldSchema.defaultEditorProps}
        data-testclass="string-type-textfield"
        value={value}
        label={fieldSchema.label || field}
        onChange={(event) => setValue(event.target.value)}
        disabled={processing}
        inputProps={{ "data-testclass": "string-type-input" }}
        
      />
    );
  }

  if (type === NUMBER_TYPE || type === INT_TYPE) {
    return (
      <TextField
        {...textFieldProps}
        value={value}
        label={fieldSchema.label || field}
        onChange={(event) => setValue(event.target.value)}
        type="number"
        disabled={processing}
      />
    );
  }

  if (type === EXTERNAL_ID_TYPE) {
    return (
      <CollectionSelector
        q={fieldSchema.selectorData.query}
        displayField={fieldSchema.selectorData.displayField}
        selectElementCallback={(value) => setValue(value)}
      />
    );
  }

  if (type === STRING_OPTIONS_TYPE) {
    const label = fieldSchema.label || field;
    return (
      <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select 
          value={value} 
          label={label}
          onChange={(event) => setValue(event.target.value)}
        >
          {fieldSchema.options.map((option) => {
            return (
              <MenuItem
                key={`option-${field}-${option.value}`}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            )}
          )}
        </Select>
      </FormControl>
    );
  }

  if (type === TIMESTAMP_TYPE) {
    return (
      <DateTimePicker
        label={fieldSchema.label || field}
        datetime={value}
        setDatetime={setValue}
      />
    );
  }

  if (type === AVATAR_TYPE) {
    return (
      <Stack alignItems="center" justifyContent="center" spacing={1}>
        <Avatar
          alt={fieldSchema.label}
          src={value}
          data-testclass="image-type-avatar"
        />
        <ImageUploader
          successCallback={(image) => setValue(image.imageUrl)}
          bucketName={fieldSchema.bucket}
          buttonLabel={`Select ${fieldSchema.label}`}
        />
      </Stack>
    );
  }

  return <div>Unkown field editor for type {type}</div>;
}
