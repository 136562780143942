import { TableCell, Typography } from "@mui/material";
import { fontSize } from "@mui/system";
import React from "react";

export default function HeaderCell({
  children, 
  ...props
}) {
  
  return (
    <TableCell
      {...props}
    >
      <Typography
        fontWeight="bold"
        fontSize="0.8rem"
      >
        {children}
      </Typography>
    </TableCell>
  );

}
