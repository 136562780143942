// @lib-generic-ui
import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const modalsSlice = createSlice({
  name: "modalsSlice",
  initialState: initialState,
  reducers: {
    setFocusRef(state, action) {
      state.focusRef = action.payload;
    },
    openModal(state, action) {
      const modal = action.payload;
      if(!state.hasOwnProperty(modal)) {
        state[modal] = {open: false};
      }
      state[modal].open = true;
    },
    closeModal(state, action) {
      const modal = action.payload;
      if(!state.hasOwnProperty(modal)) {
        state[modal] = {open: false};
      }
      state[modal].open = false;
    }
  },
});

export const modalsActions = modalsSlice.actions;

export default modalsSlice.reducer;
