import React from "react";
import { diffString } from "utils/diff";
import "diff.css";
import MDEditor from "@uiw/react-md-editor";

export default function Diff({original, modified}) {

  const diff = diffString(original, modified);

  console.log(diff);

  return (
    <MDEditor.Markdown
      source={diff}
      style={{ 
        whiteSpace: "pre-wrap", 
        marginTop: "20px", 
        padding: "10px",
        borderRadius: "8px",
      }}
    />
  );

}
