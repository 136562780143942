import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import AlertMessage from "components/react-lib/generic-ui/AlertMessage";
import LabelledOutline from "components/react-lib/generic-ui/LabelledOutline";
import HeaderCell from "components/table/HeaderCell";
import React from "react";

export default function ModelPrice({ model, pricing }) {
  const formatPrice = (price) => {
    return `${price.toFixed(2)} $`;
  };

  if (model.type === "text/image") {
    return (
      <LabelledOutline label={model.name}>
        <Stack spacing={2}>
          <Typography>Text to image model.</Typography>
          <Stack>
            <Typography textAlign="center" fontFamily="'Anton', sans-serif">
              Prices per image
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <HeaderCell align="left">Generation</HeaderCell>
                  <TableCell align="right">
                    {formatPrice(model.pricing.cost)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <HeaderCell align="left">Message Fee</HeaderCell>
                  <TableCell align="right">
                    {formatPrice(pricing.fees.sendMessage)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <HeaderCell align="left">File Processing</HeaderCell>
                  <TableCell align="right">
                    {formatPrice(pricing.fees.fileProcessing)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <HeaderCell align="left">Total</HeaderCell>
                  <TableCell align="right">
                    {formatPrice(
                      model.pricing.cost +
                        pricing.fees.sendMessage +
                        pricing.fees.fileProcessing
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Stack>
        </Stack>
      </LabelledOutline>
    );
  }

  if (model.type === "multimodal/text") {
    return (
      <LabelledOutline label={model.name}>
        <Stack spacing={2}>
          <Typography>Multi-modal input text generator model.</Typography>
          <Stack>
            <Typography textAlign="center" fontFamily="'Anton', sans-serif">
              Prices per {model.pricing.factor} tokens
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <HeaderCell align="left">Input Token Price</HeaderCell>
                  <TableCell align="right">{model.pricing.input} $</TableCell>
                </TableRow>
                <TableRow>
                  <HeaderCell align="left">Output Token Price</HeaderCell>
                  <TableCell align="right">{model.pricing.output} $</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Stack>
          <Stack>
            <Typography textAlign="center" fontFamily="'Anton', sans-serif">
              Processing fees
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <HeaderCell align="left">Message</HeaderCell>
                  <TableCell align="right">
                    {pricing.fees.sendMessage} $
                  </TableCell>
                </TableRow>
                <TableRow>
                  <HeaderCell align="left">File Processing</HeaderCell>
                  <TableCell align="right">
                    {pricing.fees.fileProcessing} $
                  </TableCell>
                </TableRow>
                <TableRow>
                  <HeaderCell align="left">URL Processing</HeaderCell>
                  <TableCell align="right">
                    {pricing.fees.urlProcessing} $
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Stack>
          <Stack>
            <Typography textAlign="center" fontFamily="'Anton', sans-serif">
              Audio fees
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <HeaderCell align="left">
                    Text to audio (per character)
                  </HeaderCell>
                  <TableCell align="right">
                    {pricing.audio.costPerCharacter} $
                  </TableCell>
                </TableRow>
                <TableRow>
                  <HeaderCell align="left">
                    Audio to text (per second)
                  </HeaderCell>
                  <TableCell align="right">
                    {pricing.audio.costPerSecond} $
                  </TableCell>
                </TableRow>
                <TableRow>
                  <HeaderCell align="left">Processing fee</HeaderCell>
                  <TableCell align="right">
                    {pricing.fees.urlProcessing} $
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Stack>
        </Stack>
      </LabelledOutline>
    );
  }

  return <AlertMessage severity="error">Unknown model type.</AlertMessage>;
}
