import Loader from "components/react-lib/generic-ui/Loader";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { editorActions } from "store/editor";
import MarkdownEditor from "./MarkdownEditor";
import { createDocument } from "utils/editor";
import { useAuth } from "context/react-lib/auth/AuthProvider";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "firebaseApp";
import { COLLECTIONS } from "collections";

export default function ActiveDocument() {
  
  const auth = useAuth();
  const dispatch = useDispatch();
  const uid = auth.currentUser?.uid;

  const editorState = useSelector((state) => state.editor);

  const updateDocument = async (value) => {
    if(!editorState.activeDocument) {
      await createDocument(value, () => {}, uid, dispatch);
    } else {
      dispatch(editorActions.updateActiveDocument(value));
    }
  }

  const handelSave = async (content = null) => {
    if(editorState.activeDocument) {
      await updateDoc(doc(db, COLLECTIONS.mdDocuments, editorState.activeDocument.id), {
        content: content || editorState.activeDocument.content,
      });
    }
  }

  const value = editorState.activeDocument ? editorState.activeDocument.content : "";

  return (
    <MarkdownEditor
      value={value}
      setValue={updateDocument}
      save={handelSave}
    />
  );

}
