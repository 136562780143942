// @lib-generic-ui
import React from "react";
import EditDocumentForm from "components/react-lib/generic-ui/firestore/EditDocumentForm";
import ReduxModal from "components/react-lib/generic-ui/modals/ReduxModal";
import { Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import { modalsActions } from "store/react-lib/generic-ui/modals";

export default function EditDocumentModal({
  name,
  title,
  documentRef,
  fieldsToEdit,
  isCreate,
  createExtraData,
  saveCallback,
  errorCallback,
}) {

  const dispatch = useDispatch();

  return (
    <ReduxModal
      name={name}
      title={title}
      fullWidth
      maxWidth="sm"
      renderContent={() => (
        <Stack pt={1}>
          <EditDocumentForm
            documentRef={documentRef}
            saveCallback={() =>  {
              if(saveCallback) {
                saveCallback();
              }
              dispatch(modalsActions.closeModal(name));
            }}
            errorCallback={errorCallback}
            fieldsToEdit={fieldsToEdit}
            isCreate={isCreate}
            createExtraData={createExtraData}
          />
        </Stack>
      )}
    />
  );
}
