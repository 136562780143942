import { createSlice } from "@reduxjs/toolkit";
import { TEXT_TYPE, URL_TYPE } from "utils/attachments";
import slugify from 'slugify';

const initialState = {
  hasShare: false,
  timestamp: null,
  title: null,
  text: null,
  url: null,
  shown: null,
  type: null,
  attachment: null,
}

function urlToRelevantName(urlString, maxLen = 50) {
  try {
    const url = new URL(urlString);
    const segments = url.pathname
      .split('/')
      .map(s => s.trim())
      .filter(Boolean);
    if (!segments.length) {
      return url.hostname.replace(/^www\./, '');
    }
    let bestSegment = '';
    let bestScore = -1;
    for (const seg of segments) {
      if (!/[a-zA-Z]/.test(seg)) {
        continue;
      }
      const score = seg.replace(/[^a-zA-Z0-9-]/g, '').length;
      if (score > bestScore) {
        bestScore = score;
        bestSegment = seg;
      }
    }
    if (!bestSegment) {
      bestSegment = segments[segments.length - 1];
    }
    let slug = bestSegment
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-') 
      .replace(/^-+|-+$/g, '');
    if (slug.length > maxLen) {
      slug = slug.slice(0, maxLen).replace(/-+$/g, '');
    }
    return slug;
  } catch (err) {
    console.error('Invalid URL:', err);
    return null;
  }
}

function textToMemorableName(text, maxWords = 5) {
  const stopwords = new Set(['the', 'a', 'of', 'to', 'by', 'and', 'for']);
  let words = text.toLowerCase().split(/\s+/);
  words = words.slice(0, maxWords);
  words = words.filter(word => !stopwords.has(word));
  let slug = words
    .join(' ')
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/^-+|-+$/g, '');
  return slug;
}

function urlToMemorableName(urlString, maxLen = 30) {
  try {
    const url = new URL(urlString);
    const domain = url.hostname.replace(/^www\./, '');
    const [firstSegment] = url.pathname.split('/').filter(Boolean);
    let combined = domain + (firstSegment ? `-${firstSegment}` : '');
    let slug = slugify(combined, {
      lower: true,
      strict: true,
    });
    if (slug.length > maxLen) {
      slug = slug.substring(0, maxLen).replace(/-+$/g, '');
    }
    return slug;
  } catch (error) {
    console.error('Invalid URL:', error);
    return null;
  }
}

const isValidUrl = (text) => {
  try {
    return Boolean(new URL(text));
  } catch (e) {
    console.log("ERROR", e);
    return false;
  }
};

const shareSlice = createSlice({
  name: "shareSlice",
  initialState: initialState,
  reducers: {
    reset() {
      return initialState;
    },
    updateShare(state, action) {
      const { title, text, url, shown } = action.payload;
      if(text) {
        const isUrl = isValidUrl(text);
        if(isUrl) {
          state.text = text;
          state.type = URL_TYPE.id;
          state.attachment = {
            url: text,
            name: urlToRelevantName(text),
            content: "",
          };
        } else {
          state.url = text;
          state.type = TEXT_TYPE.id;
          state.attachment = {
            content: text,
            name: textToMemorableName(text),
          };
        }
        state.hasShare = true;
      }
      state.shown = shown;
    }
  }
});

export const shareActions = shareSlice.actions;

export default shareSlice.reducer;