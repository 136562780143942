// @lib-generic-ui
import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useState } from 'react';
import dayjs from 'dayjs';
import { MobileDateTimePicker } from '@mui/x-date-pickers';

export default function DateTimePicker({ datetime, setDatetime, ...props }) {

  const [value, setValue] = useState(datetime && datetime.timestamp ?  dayjs(new Date(datetime.timestamp)) : "");

  const handleChange = (date) => {
    const year = date.year();
    const month = date.month() + 1;
    const day = date.date();
    const hour = date.hour();
    const minute = date.minute();

    const datetime = {
      year, 
      month, 
      day, 
      hour, 
      minute,
      timestamp: date.unix() * 1000,
    }
    setValue(date);
    setDatetime(datetime);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileDateTimePicker
        {...props}
        value={value}
        onChange={handleChange}
      />
    </LocalizationProvider>
  );
}

