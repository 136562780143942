import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import BotSelector from "components/bot/BotSelector";
import ActionButton from "components/react-lib/generic-ui/ActionButton";
import AlertMessage from "components/react-lib/generic-ui/AlertMessage";
import { documentId } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { functions } from "firebaseApp";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { botActions } from "store/bot";
import { editorActions } from "store/editor";

export default function GenerateTextModal({ open, setOpen, callback }) {
  const dispatch = useDispatch();

  const botState = useSelector((state) => state.bot);
  const editorState = useSelector((state) => state.editor);

  const [prompt, setPrompt] = useState("");
  const [generating, setGenerating] = useState(false);
  const [error, setError] = useState("");

  const handleGenerate = async () => {
    try {
      setGenerating(true);
      const res = await httpsCallable(
        functions,
        "generateText"
      )({
        botId: botState.current,
        documentContext: editorState.documentContext,
        content: prompt,
        documentId: editorState.activeDocument.id,
      });
      const data = res.data;
      if (data.success) {
        setPrompt("");
        if (callback) {
          callback(data.answer);
          setOpen(false);
        }
      } else {
        setError(data.error);
        console.log(data.error);
      }
    } catch (error) {
      console.log(error);
      setError(error.message);
    } finally {
      setGenerating(false);
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
      <DialogTitle>Generate Content</DialogTitle>
      <DialogContent>
        <Stack py={1} spacing={1}>
          <BotSelector
            bots={botState.bots.filter((bot) => !Boolean(bot.isTool))}
            selectedBot={botState.current}
            setSelectedBot={(botId) => dispatch(botActions.selectBot(botId))}
          />
          <TextField
            autoFocus
            label="What can I write for you?"
            value={prompt}
            onChange={(event) => setPrompt(event.target.value)}
            multiline
            maxRows={5}
          />
          {error && <AlertMessage severity="error">{error}</AlertMessage>}
        </Stack>
      </DialogContent>
      <DialogActions>
        <ActionButton
          variant="contained"
          processing={generating}
          disabled={!prompt}
          onClick={handleGenerate}
        >
          Generate
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
}
