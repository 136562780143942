import React, { useState, useRef } from "react";
import { IconButton, Stack, TextField, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { useDispatch, useSelector } from "react-redux";
import { chatActions } from "store/chat";
import { httpsCallable } from "firebase/functions";
import { db, functions } from "firebaseApp";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import ContextSizeSelector from "./ContextSizeSelector";
import { colors } from "colors";
import { useAuth } from "context/react-lib/auth/AuthProvider";
import AllertMessage from "components/react-lib/generic-ui/AlertMessage";
import { COLLECTIONS } from "collections";
import { createConversation } from "utils/conversations";
import BotFiles from "components/bot/BotFiles";
import RecordButton from "components/react-lib/generic-ui/RecordButton";
import AttachFileButton from "../attachments/AttachFileButton";
import ClearIcon from "@mui/icons-material/Clear";

export default function ChatInput({ ...props }) {
  
  const dispatch = useDispatch();
  const auth = useAuth();
  const uid = auth.currentUser.uid;

  const botError = useSelector((state) => state.chat.botError);
  const botState = useSelector((state) => state.bot);
  const botId = botState.current;
  const bot = botState.idMap[botId];
  const activeConversation = botState.activeConversation;

  const cancelations = useRef({});

  const prompt = useSelector((state) => state.chat.prompt);
  const audioState = useSelector((state) => state.chat.botAudio[botId]) || {
    recording: false,
    audioUrl: "",
  };

  const [shiftOn, setShiftOn] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [messageId, setMessageId] = useState();
  const [answerId, setAnswerId] = useState();
  const [cancelling, setCancelling] = useState(false);
  //const [audioURL, setAudioURL] = useState("");
  const [isComposing, setIsComposing] = useState(false);

  const listening = useSelector((state) => state.chat.listening);

  const handleStartAudioRecording = () => {
    dispatch(
      chatActions.setBotAudio({
        botId: bot.id,
        audioUrl: "",
        recording: true,
      })
    );
  };

  const setAudioUrl = (audioUrl) => {
    dispatch(
      chatActions.setBotAudio({
        botId: bot.id,
        audioUrl,
        recording: false,
      })
    );
  };

  const convertBlobURLToBase64 = async (blobURL) => {
    try {
      const response = await fetch(blobURL);
      if (!response.ok) {
        throw new Error("Failed to fetch blob data");
      }
      const blob = await response.blob();
      return await convertBlobToBase64(blob);
    } catch (error) {
      console.error("Error converting blob URL to Base64:", error);
      throw error;
    }
  };

  const convertBlobToBase64 = async (blob) => {
    try {
      const reader = new FileReader();
      const result = await new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result.split(",")[1]);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });

      return result;
    } catch (error) {
      console.error("Error converting blob to Base64:", error);
      throw error;
    }
  };

  const handleSendMessage = async () => {
    dispatch(chatActions.clearBotError(botId));
    dispatch(chatActions.setTyping(false));
    dispatch(chatActions.setBotProcessing(true));
    setProcessing(true);
    let botMessageRef;
    let messageRef;
    let conversation = activeConversation;
    try {
      if (!conversation) {
        conversation = await createConversation(bot.id, uid, dispatch);
      }
      const messagesRef = collection(
        db,
        COLLECTIONS.bots,
        botState.current,
        COLLECTIONS.conversations,
        conversation.id,
        COLLECTIONS.messages
      );
      const messageData = {
        botId,
        contextSize: botState.contextSize,
        message: {
          uid,
          botId,
          audioUrl: audioState.audioUrl,
          conversationId: conversation.id,
          content: prompt,
          createdAt: new Date().getTime(),
          role: "user",
          type: audioState.audioUrl ? "audio" : "text",
          bookmarked: false,
          cancelled: false,
          processing: true,
        },
      };
      messageRef = await addDoc(messagesRef, messageData.message);
      setMessageId(messageRef.id);
      const pendingBotMessage = {
        type: "pending",
        content: "...",
        role: "assistant",
        botId: bot.id,
        conversationId: conversation.id,
        createdAt: new Date().getTime(),
        bookmarked: false,
        messageId: messageRef.id,
        processing: true,
        cancelled: false,
      };
      botMessageRef = await addDoc(messagesRef, pendingBotMessage);
      dispatch(
        chatActions.setBotProcessing({ botId: bot.id, processing: true })
      );
      setAnswerId(botMessageRef.id);
      const payload = {
        prompt,
        botId,
        conversationId: conversation.id,
        messageId: messageRef.id,
        botMessageId: botMessageRef.id,
        contextSize: botState.contextSize,
      };
      if (audioState.audioUrl) {
        payload.audioBase64 = await convertBlobURLToBase64(audioState.audioUrl);
      }
      const res = await httpsCallable(functions, "sendMessageV2")(payload);
      const data = res.data;
      console.log(data);
      if (!data.success) {
        if (botMessageRef) deleteDoc(botMessageRef);
        await deleteDoc(messageRef);
        dispatch(
          chatActions.setBotError({
            error: data.error,
            botId,
          })
        );
      } else {
        if (!cancelations.current[messageRef.id]) {
          dispatch(chatActions.setPrompt(""));
          dispatch(chatActions.clearBotAudio({ botId: bot.id }));
          setAudioUrl("");
        }
      }
    } catch (error) {
      console.log(error);
      dispatch(
        chatActions.setBotError({
          error: "An error occurred. Try again later.",
          botId,
        })
      );
    } finally {
      dispatch(
        chatActions.setBotProcessing({ botId: bot.id, processing: false })
      );
      setProcessing(false);
    }
  };

  const disabled = () => {
    if (shiftOn) return true;
    if (!bot) return true;
    if (processing) return true;
    if (listening) return true;
    return !Boolean(prompt) && !Boolean(audioState?.audioUrl);
  };

  const handleKeyDown = (event) => {
    if (disabled()) return;
    setShiftOn(Boolean(event.shiftKey));
    // Only send the message if composition is not active
    if (event.key === "Enter" && !event.shiftKey && !isComposing) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleKeyUp = (event) => {
    setShiftOn(Boolean(event.shiftKey));
  };

  const handleOnFocus = () => {
    dispatch(chatActions.setTyping(true));
  };

  const handleOnBlur = () => {
    dispatch(chatActions.setTyping(false));
  };

  const handleCancel = async () => {
    try {
      cancelations.current[messageId] = true;
      setCancelling(true);
      await updateDoc(
        doc(
          db,
          COLLECTIONS.bots,
          bot.id,
          COLLECTIONS.conversations,
          activeConversation.id,
          COLLECTIONS.messages,
          messageId
        ),
        {
          cancelled: true,
        }
      );
      await updateDoc(
        doc(
          db,
          COLLECTIONS.bots,
          bot.id,
          COLLECTIONS.conversations,
          activeConversation.id,
          COLLECTIONS.messages,
          answerId
        ),
        {
          cancelled: true,
        }
      );
      setMessageId();
      setAnswerId();
      setProcessing(false);
    } catch (error) {
      console.log(error);
    } finally {
      setCancelling(false);
    }
  };

  const renderSendButton = () => {
    if (processing || listening) {
      const cancelDisabled = cancelling;
      return (
        <IconButton
          color="primary"
          onClick={handleCancel}
          disabled={cancelDisabled}
        >
          <StopCircleIcon
            sx={{ color: colors.white, opacity: cancelDisabled ? 0.25 : 1 }}
          />
        </IconButton>
      );
    } else {
      const isDisabled = disabled();
      return (
        <Stack alignItems="center">
          <IconButton
            color="primary"
            onClick={handleSendMessage}
            disabled={isDisabled}
            data-testid="send-message-button"
          >
            <SendIcon
              sx={{ color: colors.white, opacity: disabled() ? 0.25 : 1 }}
            />
          </IconButton>
          <Typography
            variant="caption"
            sx={{
              color: colors.white,
            }}
          >
            {audioState.audioUrl ? "audio" : prompt ? "text" : ""}
          </Typography>
        </Stack>
      );
    }
  };

  const handlePromptChange = (newPrompt) => {
    dispatch(chatActions.clearBotError());
    dispatch(chatActions.setPrompt(newPrompt));
  };

  const handleClearRecording = () => {
    dispatch(
      chatActions.setBotAudio({
        botId: bot.id,
        audioUrl: "",
        recording: false,
      })
    );
  };

  return (
    <Stack spacing={1} width="100%" maxWidth="748px">
      {botError && botError[botId] && (
        <AllertMessage severity="error" data-testid="chat-error-message">
          {botError[botId]}
        </AllertMessage>
      )}
      <BotFiles bot={bot} />
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row">
          <RecordButton
            startRecordingCallback={handleStartAudioRecording}
            //endRecordingCallback={setAudioUrl}
            clearRecordingCallback={handleClearRecording}
            audioURL={audioState.audioUrl}
            setAudioURL={setAudioUrl}
            activeColor="pink"
          />
          <AttachFileButton
            buttonProps={{
              color: "white",
            }}
          />
        </Stack>
        <Stack direction="row">
          {prompt && (
            <IconButton onClick={() => handlePromptChange("")}>
              <ClearIcon sx={{ color: colors.white }} />
            </IconButton>
          )}
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        <ContextSizeSelector />
        <TextField
          {...props}
          placeholder="How can I help you?..."
          data-testid="chat-input"
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          onCompositionStart={() => setIsComposing(true)}
          onCompositionEnd={() => setIsComposing(false)}
          disabled={
            //botState.loading ||
            processing ||
            listening ||
            audioState?.recording ||
            Boolean(audioState?.audioUrl)
          }
          value={prompt}
          onInput={(event) => handlePromptChange(event.target.value)}
          multiline
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          maxRows={6}
          InputProps={{
            style: {
              backgroundColor: colors.drakGray,
              color: colors.white,
            },
          }}
        />
        {renderSendButton()}
      </Stack>
      <Typography
        variant="caption"
        sx={{
          color: colors.white,
        }}
        textAlign="center"
      >
        AI can make mistakes. Check important information.
      </Typography>
    </Stack>
  );
}
