import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeDocument: null,
  tools: [],
  loadingTools: true,
  documentContext: "",
}

const editorSlice = createSlice({
  name: "editorSlice",
  initialState: initialState,
  reducers: {
    reset() {
      return initialState;
    },
    initializeTools(state, action) {
      const tools = action.payload;
      state.tools = tools;
      state.loadingTools = false;
    },
    updateActiveDocument(state, action) {
      state.activeDocument.content = action.payload;
    },
    setActiveDocument(state, action) {
      state.activeDocument = action.payload;
    },
    setDocumentContext(state, action) {
      state.documentContext = action.payload;
    },
  }
});

export const editorActions = editorSlice.actions;

export default editorSlice.reducer;