// @lib-generic-ui
import React, { useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import ActionButton from "./ActionButton";
import { getFileUploadUrl } from "utils/storageUtils";
import PdfPageRangeSelector from "./PdfPageRangeSelector";

const FileUploader = ({ successCallback }) => {

  const [file, setFile] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [options, setOptions] = useState({});

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    event.target.value = null;
    setOptions({});
  };

  const handleUpload = async () => {
    if (!file) {
      return;
    }

    setProcessing(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("options", JSON.stringify(options));

    try {
      const response = await fetch(getFileUploadUrl(), {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      if (responseData.error) {
        console.log(responseData.error);
      } else {
        setFile(null);
        successCallback(responseData);
      }
    } catch (error) {
      console.error("Upload error:", error);
    } finally {
      setProcessing(false);
    }
  };

  const renderOptionsForm = () => {
    if(!file) return null;
    switch(file.type) {
      case "application/pdf":
        return <PdfPageRangeSelector 
          pdfFile={file} 
          onPageRangeSelected={setOptions}
        />
      break
    }
    return null;
  }

  return (
    <Stack spacing={1}>
      {file && (
        <Typography
          textAlign="center"
          variant="caption"
          data-testid="text-file-uploader-file-name"
        >
          {file.name}
        </Typography>
      )}
      <Stack direction="row" spacing={1}>
        <Button color="primary" component="label" variant="outlined">
          Select File
          <input
            data-testid="text-file-uploader-file-selector-input"
            type="file"
            onChange={handleFileChange}
            hidden
            accept=".txt,.pdf,.docx,.mp3" // updated to accept more file types
          />
        </Button>
        <ActionButton
          data-testid="text-file-uploader-upload-button"
          variant="contained"
          color="primary"
          onClick={handleUpload}
          disabled={!file || processing}
          processing={processing}
        >
          {processing ? "Upload File" : "Upload File"}
        </ActionButton>
      </Stack>
      {renderOptionsForm()}
    </Stack>
  );
};

export default FileUploader;
