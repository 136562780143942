import {
  LinearProgress,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import useScrollIntoView from "hooks/react-lib/generic-ui/useScrollIntoView";
import React from "react";
import { serializeCodeChildren } from "utils/markdown";

export default function AIBlock({ children, currentPrompt }) {
  
  const prompt = serializeCodeChildren(children);

  const processing = prompt === currentPrompt;

  const ref = useScrollIntoView(processing);

  if (processing) {
    return (
        <Stack
          p={1}
          ref={ref}
          spacing={1}
        >
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Stack>
    );
  }

  return (
    <Paper>
      <Stack p={1}>
        <Typography fontWeight="bold">AI Content</Typography>
        <Typography
          sx={{
            overflowX: "auto",
          }}
        >{prompt}</Typography>
      </Stack>
    </Paper>
  );
}
