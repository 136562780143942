import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { colors } from "colors";
import { IconButton } from "@mui/material";
import ContentEditorModal from "./ContentEditorModal";

export default function ContentEditorButton({ paragraphContent, updateParagraph }) {

  const [editorOpen, setEditorOpen] = useState(false);

  const onClose = () => {
    setEditorOpen(false);
  }

  return (
    <>
      <ContentEditorModal 
        open={editorOpen} 
        onClose={onClose} 
        paragraphContent={paragraphContent}
        updateParagraph={updateParagraph}
      />
      <IconButton
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
          color: colors.white,
        }}
        onClick={() => setEditorOpen(true)}
      >
        <EditIcon />
      </IconButton>
    </>
  );
}
