// @lib-generic-ui
import { FormControl, InputLabel, ListItemIcon, MenuItem, Select, Typography } from "@mui/material";
import React from "react";

export default function Selector({
  label,
  value,
  setValue,
  options,
  valueField,
  labelField,
  allowEmpty = true,
  getIcon = null,
}) {
  const realOptions = allowEmpty
    ? [{ id: "", [valueField]: "", [labelField]: "" }, ...options]
    : [...options];

  return (
    <FormControl>
      <InputLabel>{label}</InputLabel>
      <Select
        data-testclass="generic-selector"
        value={value}
        label={label}
        onChange={(event) => setValue(event.target.value)}
      >
        {realOptions.map((option) => (
          <MenuItem
            data-testclass="generic-selector-menu-item"
            key={option.id}
            value={option[valueField]}
          >
            {getIcon && (
              <ListItemIcon>
                {getIcon(option)}
              </ListItemIcon>
            )}
            <Typography variant="inherit" noWrap>
              {option[labelField]}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
