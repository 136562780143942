import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { useAuth } from "context/react-lib/auth/AuthProvider";
import CreateGroupForm from "./CreateGroupForm";
import MyUserGroups from "./MyUserGroups";
import ReduxModal from "components/react-lib/generic-ui/modals/ReduxModal";

export default function GroupsModal() {

  const auth = useAuth();
  const uid = auth.currentUser?.uid;

  return (
    <ReduxModal
      name="groups"
      title="Manage Groups"
      renderContent={() => (
        <>
          <CreateGroupForm uid={uid} />
          <MyUserGroups uid={uid} />
        </>
      )}
    />
  );
}
