import { Button } from "@mui/material";
import ActionButton from "components/react-lib/generic-ui/ActionButton";
import { httpsCallable } from "firebase/functions";
import { functions } from "firebaseApp";
import React, { useState } from "react";

export default function ToolButton({ tool, content, setResult }) {

  const [processing, setProcessing] = useState(false);

  const process = async () => {
    try {
      setProcessing(true);
      const res = await httpsCallable(functions, "useTool")({
        botId: tool.id,
        content,
      });
      const data = res.data;
      if(data.success) {
        setResult(data.answer);
      } else {
        console.log(data);
      }
    } catch(error) {
      console.log(error);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <ActionButton 
      variant="contained" 
      processing={processing}
      onClick={process}
    >
      {tool.name}
    </ActionButton>
  );
}
