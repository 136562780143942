import React, { useEffect, useState } from "react";
import TextAttachment from "./TextAttachment";
import URLSelector from "./URLSelector";
import FileUploader from "components/react-lib/generic-ui/FileUploader";
import ImageUploader from "components/react-lib/generic-ui/ImageUploader";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import Selector from "components/react-lib/generic-ui/Selector";
import ActionButton from "components/react-lib/generic-ui/ActionButton";
import AlertMessage from "components/react-lib/generic-ui/AlertMessage";

export const URL = { id: "url", label: "URL" };
export const FILE = { id: "file", label: "File" };
export const IMAGE = { id: "image", label: "Image" };
export const TEXT = { id: "text", label: "Text" };

export default function AttachFileModal({
  open,
  setOpen,
  processAttachment,
  supportedTypes,
}) {

  const [attachmentType, setAttachmentType] = useState(supportedTypes[0].id);
  const [attachment, setAttachment] = useState(null);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    setAttachment(null);
  }, [attachmentType]);

  useEffect(() => {
    setAttachment(null);
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const canAttach = () => {
    switch (attachmentType) {
      case URL.id:
        return Boolean(attachment?.content);
      case FILE.id:
        return Boolean(attachment?.filename);
      case IMAGE.id:
        return Boolean(attachment?.imageUrl);
      case TEXT.id:
        return Boolean(attachment?.content);
    }
    return false;
  };

  const handleFileUploaded = (file) => {
    setAttachment(file);
  };

  const handleImageUploaded = (image) => {
    setAttachment(image);
  };

  const renderForm = () => {
    switch (attachmentType) {
      case TEXT.id:
        return <TextAttachment value={attachment} setValue={setAttachment} />;
      case URL.id:
        return <URLSelector value={attachment} setValue={setAttachment} />;
      case FILE.id:
        return <FileUploader successCallback={handleFileUploaded} />;
      case IMAGE.id:
        return (
          <ImageUploader
            successCallback={handleImageUploaded}
            bucketName="inputImages"
            preview={true}
          />
        );
    }
    console.warn(`Invalid attachment type ${attachmentType}`);
    return null;
  };

  const handleAttach = async () => {
    await processAttachment(attachmentType, attachment, setProcessing);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Attach</DialogTitle>
      <DialogContent>
        <Stack mt={1} spacing={1}>
          <Selector
            label="Attachment type"
            value={attachmentType}
            setValue={setAttachmentType}
            options={supportedTypes}
            valueField="id"
            labelField="id"
            allowEmpty={false}
          />
          {renderForm()}
          {attachment && attachmentType === FILE.id && (
            <AlertMessage severity="success">
              File {attachment.filename} uploaded
            </AlertMessage>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <ButtonGroup fullWidth>
          <Button variant="contained" color="pink" onClick={handleClose}>
            Cancel
          </Button>
          <ActionButton
            data-testid="finalize-attach-button"
            variant="contained"
            color="black"
            disabled={!canAttach()}
            onClick={handleAttach}
            processing={processing}
          >
            Attach
          </ActionButton>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}
