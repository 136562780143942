export const COLLECTIONS = {
  bots: "bots",
  conversations: "conversations",
  messages: "messages",
  users: "users",
  bookmarks: "bookmarks",
  mdDocuments: "mdDocuments",
  documentAttachments: "documentAttachments",
  supportRequests: "supportRequests",
  attachments: "attachments",
}