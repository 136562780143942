import {
  CircularProgress,
  Drawer,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuList,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { colors } from "colors";
import NewConversationButton from "components/chat/NewConversationButton";
import { editorActions } from "store/editor";

export default function DocumentDrawerMenu({ documentsSnap, loading, error }) {

  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);

  const editorState = useSelector((state) => state.editor);

  if (error) {
    return null;
  }

  if (loading) {
    return <CircularProgress size={24} />;
  }

  if (!documentsSnap) {
    return null;
  }

  const documents = documentsSnap.docs.map((snap) => ({
    id: snap.id,
    ...snap.data(),
  }));

  const handleSelectDocument = (document) => {
    dispatch(editorActions.setActiveDocument(document));
    setMenuOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={() => setMenuOpen(true)}
        color="white"
        data-testid="main-menu-button"
      >
        <QuestionAnswerIcon />
      </IconButton>
      <Drawer
        data-testid="conversation-menu-drawer"
        open={menuOpen}
        anchor="right"
        onClose={() => setMenuOpen(false)}
        sx={{
          width: "300px",
          "& .MuiDrawer-paper": {
            width: "300px",
            boxSizing: "border-box",
            p: 2,
          },
        }}
      >
        <Stack alignItems="center" justifyContent="center">
          <NewConversationButton
            onCreate={() => setMenuOpen(false)} // Pass callback to close the modal
          />
        </Stack>
        <MenuList
          sx={{
            overflowY: "auto",
          }}
        >
          {documents.map((document, index) => {
            return (
              <ListItem
                key={document.id}
                // secondaryAction={
                //   <ConversationMenu
                //     previousConversation={
                //       index === 0 ? null : conversationDocs[index - 1]
                //     }
                //     nextConversation={
                //       index + 1 >= conversationDocs.length
                //         ? null
                //         : conversationDocs[index + 1]
                //     }
                //     conversation={conversation}
                //     color={colors.black}
                //   />
                // }
                sx={{
                  borderRadius: "4px",
                  border:
                    document.id === editorState.activeDocument?.id
                      ? `1px solid ${colors.black}`
                      : "",
                }}
              >
                <ListItemButton onClick={() => handleSelectDocument(document)}>
                  <ListItemText
                    sx={{
                      color: colors.black,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    primary={<Typography>{document.name}</Typography>}
                    secondary={
                      <Typography variant="caption">
                        {new Date(document.createdAt).toLocaleString()}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </MenuList>
      </Drawer>
    </>
  );
}
