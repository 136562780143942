import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { colors } from "colors";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/react-lib/generic-ui/Loader";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, query, where } from "firebase/firestore";
import { db } from "firebaseApp";
import { COLLECTIONS } from "collections";
import { useAuth } from "context/react-lib/auth/AuthProvider";
import { editorActions } from "store/editor";
import DocumentMenu from "./DocumentMenu";

export default function DocumentList({
  documentsSnap,
  loading,
  error,
}) {
  
  const auth = useAuth();
  const uid = auth.currentUser.uid;

  const activeDocumentId = useSelector((state) => state.editor.activeDocument?.id);
  const dispatch = useDispatch();


  useEffect(() => {
    if(documentsSnap && !documentsSnap.empty) {
      const doc = {
        id: documentsSnap.docs[0].id,
        ...documentsSnap.docs[0].data(),
      }
      if(!activeDocumentId) {
        dispatch(editorActions.setActiveDocument(doc));
      }
    }
  }, [documentsSnap]);

  if (error) {
    console.log(error);
    return null;
  }

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  const renderDocuments = () => {
    if (!documentsSnap) return null;
    if (documentsSnap.docs.length === 0) {
      return (
        <ListItem>
          <ListItemText 
            sx={{
              color: colors.white,
            }}>
              No documents.
          </ListItemText>
        </ListItem>
      );
    }
    const documents = documentsSnap.docs.map((snap) => ({
      id: snap.id,
      ...snap.data(),
    }));
    return documents.map((document, index) => {
      return (
        <ListItem
          key={document.id}
          secondaryAction={(
            <DocumentMenu
              previous={index === 0 ? null : documents[index - 1]}
              next={index + 1 >= documents.length ? null : documents[index + 1]}
              document={document}
              color="white"
            />
          )}
          sx={{
            borderRadius: "4px",
            backgroundColor: document.id === activeDocumentId ? colors.drakGray : colors.black,
          }}
        >
          <ListItemButton
            onClick={() =>
              dispatch(editorActions.setActiveDocument(document))
            }
          >
            <ListItemText
              sx={{
                color: colors.white,
              }}
              primary={<Typography>{document.name}</Typography>}
              secondary={
                <Typography variant="caption">
                  {new Date(document.createdAt).toLocaleString()}
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>
      );
    });
  };

  return (
    <List dense disablePadding>
      {loading && (
        <ListItem>
          <Loader message="Loading" />
        </ListItem>
      )}
      {renderDocuments()}
    </List>
  );
}
