// @lib-generic-ui
import { useEffect, useRef } from "react";

export default function useScrollIntoView(shouldScroll) {
  
  const elementRef = useRef(null);

  useEffect(() => {
    if (shouldScroll && elementRef.current) {
      elementRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [shouldScroll]);

  return elementRef;

}