import { auth, db } from "firebaseApp";
import { collection, query, where } from "firebase/firestore";
import { useEffect } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { useDispatch } from "react-redux";
import { COLLECTIONS } from "collections";
import { editorActions } from "store/editor";

export default function ToolLoader() {

  const uid = auth.currentUser.uid;
  
  const dispatch = useDispatch();
  
  const q = query(
    collection(db, COLLECTIONS.bots),
    where("uid", "==", uid),
    where("isTool", "==", true),
  );

  const [snapshot, loading, error] = useCollection(q);

  if(error) {
    console.log(error);
  }

  useEffect(() => {
    if(snapshot) {
      const tools = snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}));
      dispatch(editorActions.initializeTools(tools.sort((x, y) => x.createdAt - y.createdAt)));
    }
  }, [snapshot]);

  return null;

}
