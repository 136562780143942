// @lib-generic-ui
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: "",
  severity: "",
  duration: 4000,
};

const popupSlice = createSlice({
  name: "popupSlice",
  initialState: initialState,
  reducers: {
    close(state) {
      state.message = "";
    },
    showInfo(state, action) {
      state.message = action.payload;
      state.severity = "info";
    },
    showSuccess(state, action) {
      state.message = action.payload;
      state.severity = "success";
    },
    showError(state, action) {
      state.message = action.payload;
      state.severity = "error";
    }
  },
});

export const popupActions = popupSlice.actions;

export default popupSlice.reducer;
