// @lib-generic-ui
import { Button } from "@mui/material";
import React, { useState } from "react";
import ConfirmModal from "./ConfirmModal";

export default function ButtonWithConfirm({
  children, 
  onClick,
  confirmTitle = "Confirm",
  confirmMessage = "",
  ...props
}) {

  const [confirming, setConfirming] = useState(false);

  return (
    <>
      <ConfirmModal
        open={confirming}
        setOpen={setConfirming}
        title={confirmTitle}
        message={confirmMessage}
        confirmAction={onClick}
        cancelAction={() => setConfirming(false)}
      />
      <Button
        {...props}
        onClick={() => setConfirming(true)}
      >
        {children}
      </Button>
    </>
  )
}
