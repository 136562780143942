// @lib-generic-ui
import {
  Dialog,
  DialogContent,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import EditDocumentForm from "./EditDocumentForm";
import EditIcon from "@mui/icons-material/Edit";

export default function EditDocumentMenuItem({
  documentRef,
  label = "Edit",
  iconProps = {},
  fieldsToEdit = null,
  closeMenu,
  menuItemProps = {},
  renderIcon,
  dialogProps = {},
  isCreate = false,
  createExtraData = {},
  onOpen,
}) {
  renderIcon = renderIcon ? renderIcon : () => <EditIcon {...iconProps} />;

  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = () => {
    setModalOpen(false);
    if (closeMenu) {
      closeMenu();
    }
  };

  const handleOpen = () => {
    if(onOpen) {
      onOpen();
    }
    setModalOpen(true);
  };

  return (
    <>
      <Dialog open={modalOpen} onClose={handleClose} {...dialogProps}>
        <DialogContent>
          <EditDocumentForm
            documentRef={documentRef}
            saveCallback={handleClose}
            fieldsToEdit={fieldsToEdit}
            isCreate={isCreate}
            createExtraData={createExtraData}
          />
        </DialogContent>
      </Dialog>
      <MenuItem onClick={handleOpen} {...menuItemProps}>
        <ListItemIcon>{renderIcon()}</ListItemIcon>
        <ListItemText color="black">{label}</ListItemText>
      </MenuItem>
    </>
  );
}
