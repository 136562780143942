import CreateAttachmentFromShareModal from "components/attachments/CreateAttachmentFromShareModal";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { shareActions } from "store/share";

export default function ShareListener() {
 
  const dispatch = useDispatch();
  const shareState = useSelector((state) => state.share);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        if (event.data && event.data.type === "share-target") {
          const { text, url, title, shown } = event.data;
          if(!shown) {
            dispatch(shareActions.updateShare({title, text, url, shown})); 
          }
        }
      });
      navigator.serviceWorker.ready.then((registration) => {
        console.log("registration", registration);
        registration.active.postMessage({
          action: "get-share-target",
        });
      });
    }
  }, []);

  if(!shareState.hasShare) {
    return null;
  }

  return (
    <CreateAttachmentFromShareModal 
      open={shareState.hasShare}
    />
  );
  
}