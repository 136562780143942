import {
  Button,
  ButtonGroup,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "firebaseApp";
import { useDispatch, useSelector } from "react-redux";
import { createBotActions } from "store/createBot";
import { newBotModels, modelMeta } from "utils/modelUtils";
import TextInput from "../react-lib/generic-ui/TextInput";
import { useAuth } from "context/react-lib/auth/AuthProvider";
import ButtonWithConfirm from "components/react-lib/generic-ui/ButtonWithConfirm";
import Selector from "components/react-lib/generic-ui/Selector";
import AvatarSelector from "./AvatarSelector";
import TextFileUploader from "components/react-lib/generic-ui/FileUploader";
import FileItem from "components/files/FileItem";
import { botActions } from "store/bot";
import LabelledOutline from "components/react-lib/generic-ui/LabelledOutline";
import { COLLECTIONS } from "collections";

export default function CreateBotModal({ open, setOpen }) {
  const auth = useAuth();

  const dispatch = useDispatch();
  const createBotState = useSelector((state) => state.createBot);
  const [processing, setProcessing] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const uid = auth.currentUser.uid;

  const updateFile = async (botId, fileId) => {
    if (!fileId) return;
    try {
      await updateDoc(doc(db, "files", fileId), {
        botId,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreate = async () => {
    setProcessing(true);
    try {
      if (createBotState.id) {
        await updateDoc(doc(db, COLLECTIONS.bots, createBotState.id), {
          name: createBotState.name,
          model: createBotState.model,
          systemPrompt: createBotState.systemPrompt,
          avatar: createBotState.avatar,
          contextSize: createBotState.contextSize,
          maxTokens: createBotState.maxTokens || "",
          stream:
            createBotState.stream === undefined ? false : createBotState.stream,
          fileId: createBotState.fileId || "",
          isTool: createBotState.isTool || false,
          toolButtonLabel: createBotState.toolButtonLabel || createBotState.name,
        });
        await updateFile(createBotState.id, createBotState.fileId);
        dispatch(botActions.selectBot(createBotState.id));
      } else {
        const botRef = await addDoc(collection(db, COLLECTIONS.bots), {
          uid,
          name: createBotState.name,
          model: createBotState.model,
          systemPrompt: createBotState.systemPrompt,
          avatar: createBotState.avatar,
          contextSize: createBotState.contextSize,
          maxTokens: createBotState.maxTokens || "",
          stream:
            createBotState.stream === undefined ? false : createBotState.stream,
          createdAt: new Date().getTime(),
          type: modelMeta[createBotState.model].type,
          fileId: createBotState.fileId || "",
          isTool: createBotState.isTool || false,
          toolButtonLabel: createBotState.toolButtonLabel || createBotState.name,
        });
        await updateFile(botRef.id, createBotState.fileId);
        dispatch(botActions.selectBot(botRef.id));
      }
      handleClose();
    } catch (error) {
      console.log(error);
    } finally {
      setProcessing(false);
    }
  };

  const canCreate = () => {
    if (processing) return false;
    if (!uid) return false;
    if (!createBotState.name) return false;
    if (!createBotState.model) return false;
    if (!createBotState.systemPrompt) return false;
    if (!createBotState.avatar) return false;
    if (createBotState.contextSize === "") return false;
    return true;
  };

  const handleDelete = async () => {
    try {
      await deleteDoc(doc(db, COLLECTIONS.bots, createBotState.id));
      handleClose();
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const handleFileUploaded = async (fileData) => {
    const fileRef = await addDoc(collection(db, "files"), {
      ...fileData,
      uid,
      createdAt: new Date().getTime(),
      botId: createBotState.id || "",
    });
    if (createBotState.id) {
      await updateDoc(doc(db, "bots", createBotState.id), {
        fileId: fileRef.id,
      });
    }
    dispatch(
      createBotActions.updateField({ field: "fileId", value: fileRef.id })
    );
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth data-testid="bot-modal">
      <DialogTitle>Create Bot</DialogTitle>
      <DialogContent sx={{ overflowY: "auto" }}>
        <Stack py={1} spacing={2}>
          <TextInput
            inputDataTestId="bot-name-textinput"
            key="name-input"
            autoComplete="off"
            label="Name"
            value={createBotState.name}
            setValue={(name) =>
              dispatch(
                createBotActions.updateField({ field: "name", value: name })
              )
            }
          />
          <AvatarSelector
            avatar={createBotState.avatar}
            setAvatar={(avatar) =>
              dispatch(
                createBotActions.updateField({ field: "avatar", value: avatar })
              )
            }
          />
          <Selector
            label="Model"
            value={createBotState.model}
            setValue={(model) =>
              dispatch(
                createBotActions.updateField({ field: "model", value: model })
              )
            }
            options={newBotModels.map((name) => ({ id: name }))}
            valueField="id"
            labelField="id"
          />
          <TextInput
            inputDataTestId="bot-prompt-textinput"
            fullWidth
            label="System prompt"
            helperText={`
            This prompt is used to let the bot know what kind of tasks you expect it to solve.
            Each time you talk with the bot, it will take it into account, together with
            the message. This makes it possible to specify a behavior you want the bot to
            have without having to specify each time you send a mesasge. 
            `}
            value={createBotState.systemPrompt}
            setValue={(systemPrompt) =>
              dispatch(
                createBotActions.updateField({
                  field: "systemPrompt",
                  value: systemPrompt,
                })
              )
            }
            multiline
          />
          <LabelledOutline label="Context file">
            <Stack alignItems="center" justifyContent="center">
              <FileItem fileId={createBotState.fileId} />
              <TextFileUploader successCallback={handleFileUploaded} />
            </Stack>
          </LabelledOutline>
          <TextInput
            label="Default context size"
            helperText={`
            The number of previous messages the bot takes into account when answering. By default, only the system prompt
            and the user message are considered.
          `}
            value={createBotState.contextSize}
            setValue={(contextSize) =>
              dispatch(
                createBotActions.updateField({
                  field: "contextSize",
                  value: contextSize,
                })
              )
            }
            type="number"
          />
          {/* <TextInput
            label="Max output tokens"
            helperText={`
            Limit the number of output tokens. Leave empty for unlimited tokens (up to the model limit).
            `}
            value={createBotState.maxTokens}
            setValue={(maxTokens) =>
              dispatch(
                createBotActions.updateField({
                  field: "maxTokens",
                  value: maxTokens,
                })
              )
            }
            type="number"
          /> */}
          {/* <FormControl>
            <FormControlLabel control={<Checkbox 
              color="black"
              checked={createBotState.stream}
              onChange={() => 
                dispatch(createBotActions.updateField({
                  field: "stream",
                  value: !createBotState.stream,
                }))
              }
            />} label="Steam answer" />
            <FormHelperText>
              Wether the answer is steamed word by word or given all at once. 
              Steaming is slightly more expensive.
            </FormHelperText>
          </FormControl> */}
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  color="black"
                  checked={createBotState.isTool}
                  onChange={() =>
                    dispatch(
                      createBotActions.updateField({
                        field: "isTool",
                        value: !createBotState.isTool,
                      })
                    )
                  }
                />
              }
              label="Is Tool?"
            />
            <FormHelperText>
              Wether the bot is a tool.
            </FormHelperText>
          </FormControl>
          {createBotState.isTool && (
            <TextField 
              label="Tool button label"
              value={createBotState.toolButtonLabel || createBotState.name}
              onChange={(event) =>
                dispatch(
                  createBotActions.updateField({
                    field: "toolButtonLabel",
                    value: event.target.value,
                  })
                )
              }
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <ButtonGroup fullWidth>
          <Button
            color="black"
            onClick={handleClose}
            disabled={processing}
            data-testid="bot-modal-close-button"
          >
            Cancel
          </Button>
          {createBotState.id && (
            <ButtonWithConfirm
              onClick={handleDelete}
              color="secondary"
              variant="contained"
              confirmTitle="Delete Bot"
              confirmMessage="Are you sure to delete this bot? This can't be undone."
            >
              Delete
            </ButtonWithConfirm>
          )}
          <Button
            data-testid="create-bot-button"
            variant="contained"
            disabled={!canCreate()}
            onClick={handleCreate}
          >
            {processing
              ? createBotState.id
                ? "Updaing"
                : "Creating"
              : createBotState.id
              ? "Update"
              : "Create"}
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}
