export const extractCommentsFromMarkdown = (markdown) => {
  const commentRegex = /<!--(.*?)-->/gs;
  const matches = [];
  let match;
  while ((match = commentRegex.exec(markdown)) !== null) {
    matches.push({
      raw: match[0],
      prompt: match[1].trim(),
      index: match.index,
    });
  }
  return matches;
};

export const findCodeBlocks = (langauge, markdown) => {
  const regex = /```(\w+)\n([\s\S]*?)```/g;
  let match;
  const codeBlocks = [];
  while ((match = regex.exec(markdown)) !== null) {
    if(match[1] === langauge) {
      codeBlocks.push({
        raw: match[0],
        prompt: match[2],
        startIndex: match.index,
        endIndex: match.index + match[0].length,
      });
    }
  }
  return codeBlocks;
};

export const findFirstCodeBlock = (langauge, markdown) => {
  const regex = /```(\w+)\n([\s\S]*?)```/g;
  let match;
  while ((match = regex.exec(markdown)) !== null) {
    if(match[1] === langauge) {
      return {
        raw: match[0],
        prompt: match[2],
        startIndex: match.index,
        endIndex: match.index + match[0].length,
      };
    }
  }
  return null;
};

export const serializeCodeChildren = (children) => {
  if(!children) return "";
  if(Array.isArray(children)) {
    return children.map(serializeCodeChildren).join("");  
  }
  return children.props.children;
}

export const serializeChildren = (children) => {
  if (!children) return "";

  if (typeof children === "string") {
    return children;
  }

  if(children.type === "em") {
    return `*${serializeChildren(children.props.children)}*`;
  }

  if(children.type === "strong") {
    return `**${serializeChildren(children.props.children)}**`;
  }

  if(children.type?.name === "code") {
    return `\`${serializeChildren(children.props.children)}\``;
  }

  if (Array.isArray(children)) {
    return children.map(serializeChildren).join(""); 
  }

  if(children.type === "li") {
    return `- ${serializeChildren(children.props.children)}`
  }

};