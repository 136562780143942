import React, { useRef, useEffect, useState } from "react";
import MDEditor, { getCommands } from "@uiw/react-md-editor";
import Paragraph from "components/markdown-editor/Paragraph";
import GenerateTextModal from "./GenerateTextModal";
import { useDispatch, useSelector } from "react-redux";
import { editorActions } from "store/editor";
import { findCodeBlocks, findFirstCodeBlock } from "utils/markdown";
import { httpsCallable } from "firebase/functions";
import { functions } from "firebaseApp";
import AIBlock from "./AIBlock";
import MdList from "./MdList";
import { Box, Stack, Typography } from "@mui/material";
import { colors } from "colors";
import useElementSize from "hooks/react-lib/generic-ui/useElementSize";

const MarkdownEditor = ({ value, setValue, save }) => {
  const dispatch = useDispatch();

  const botState = useSelector((state) => state.bot);
  const editorState = useSelector((state) => state.editor);

  const [generatorOpen, setGeneratorOpen] = useState(false);
  const editorRef = useRef(null);

  const editorSize = useElementSize(editorRef);

  const [generating, setGenerating] = useState(false);
  const [currentTarget, setCurrentTarget] = useState(null);
  const [history, setHistory] = useState([]);
  const [redoStack, setRedoStack] = useState([]);

  const handleChange = (newValue) => {
    setHistory((prev) => [...prev, value]);
    setRedoStack([]);
    setValue(newValue);
  };

  const handleUndo = () => {
    if (history.length > 0) {
      const lastState = history[history.length - 1];
      setRedoStack((prev) => [value, ...prev]);
      setHistory((prev) => prev.slice(0, -1));
      setValue(lastState);
    }
  };

  const handleRedo = () => {
    if (redoStack.length > 0) {
      const nextState = redoStack[0];
      setHistory((prev) => [...prev, value]);
      setRedoStack((prev) => prev.slice(1));
      setValue(nextState);
    }
  };

  const handleGenerateDocument = async () => {
    try {
      setGenerating(true);
      let currentValue = value;
      let aiBlock = findFirstCodeBlock("ai", currentValue);
      while (aiBlock !== null) {
        const context = currentValue.substring(0, aiBlock.startIndex);
        setCurrentTarget(aiBlock.prompt);
        const res = await httpsCallable(
          functions,
          "generateText"
        )({
          botId: botState.current,
          documentContext: context,
          content: aiBlock.prompt,
          documentId: editorState.activeDocument.id,
        });
        const data = res.data;
        if (data.success) {
          currentValue = currentValue.replace(aiBlock.raw, data.answer);
        } else {
          console.log(data.error);
          break;
        }
        setValue(currentValue);
        aiBlock = findFirstCodeBlock("ai", currentValue);
      }
      setCurrentTarget(null);
    } catch (error) {
      console.log(error);
    } finally {
      setGenerating(false);
    }
  };

  const updateParagraph = (originalContent, newContent) => {
    const newValue = value.replace(originalContent, newContent);
    setValue(newValue);
    handleSave(newValue);
  };

  const handleSave = (value = null) => {
    if (save) {
      save(value);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "s") {
        event.preventDefault();
        handleSave();
      }
      if ((event.metaKey || event.ctrlKey) && event.key === "z") {
        event.preventDefault();
        handleUndo();
      }
      if ((event.metaKey || event.ctrlKey) && event.key === "y") {
        event.preventDefault();
        handleRedo();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [history, redoStack]);

  const undoCommand = {
    name: "undo",
    keyCommand: "undo",
    buttonProps: { "aria-label": "Undo" },
    icon: (
      <span role="img" aria-label="undo">
        ↩️
      </span>
    ),
    execute: handleUndo,
  };

  const redoCommand = {
    name: "redo",
    keyCommand: "redo",
    buttonProps: { "aria-label": "Redo" },
    icon: (
      <span role="img" aria-label="redo">
        ↪️
      </span>
    ),
    execute: handleRedo,
  };

  const generateContent = {
    name: "generateContent",
    keyCommand: "generateContent",
    buttonProps: { "aria-label": "Generate Content" },
    icon: (
      <span role="img" aria-label="cursor">
        🤖
      </span>
    ),
    execute: () => {
      const textarea = editorRef.current.querySelector("textarea");
      let textUpToCursor = "";
      if (textarea) {
        const cursorPosition = textarea.selectionStart;
        textUpToCursor = value.slice(0, cursorPosition);
      }
      dispatch(editorActions.setDocumentContext(textUpToCursor));
      setGeneratorOpen(true);
    },
  };

  const generateDocument = {
    name: "generateDocument",
    keyCommand: "generateDocument",
    buttonProps: { "aria-label": "Generate Document" },
    icon: (
      <span role="img" aria-label="cursor">
        🪄
      </span>
    ),
    execute: () => {
      handleGenerateDocument();
    },
  };

  const handleSendToGoogleDoc = () => {
    console.log(value);
  };

  const sendToGoogleDoc = {
    name: "generateContent",
    keyCommand: "generateContent",
    buttonProps: { "aria-label": "Generate Content" },
    icon: (
      <span role="img" aria-label="cursor">
        📄
      </span>
    ),
    execute: () => {
      handleSendToGoogleDoc();
    },
  };

  function insertText(original, index, textToInsert) {
    return original.slice(0, index) + textToInsert + original.slice(index);
  }

  const insertGeneratedText = (generatedText) => {
    const textarea = editorRef.current.querySelector("textarea");
    const cursorPosition = textarea.selectionStart;
    const newValue = insertText(value, cursorPosition, generatedText);
    setValue(newValue);
    handleSave(newValue);
  };

  console.log(editorSize);

  return (
    <Stack 
      ref={editorRef} 
      sx={{ 
          height: "100%", 
          width: "100%",
      }}  
      justifyContent="center"
    >
      <GenerateTextModal
        open={generatorOpen}
        setOpen={setGeneratorOpen}
        callback={insertGeneratedText}
      />
      <MDEditor
        width={editorSize ? `${editorSize.width - 32}px` : ""}
        height={editorSize ? `${editorSize.height- 16}px` : ""}
        value={value}
        spellCheck={true}
        visibleDragbar={false}
        onChange={handleChange}
        commands={[
          ...getCommands(),
          undoCommand,
          redoCommand,
          generateContent,
          generateDocument,
          sendToGoogleDoc,
        ]}
        previewOptions={{
          components: {
            p: ({ children }) => {
              return (
                <Paragraph
                  children={children}
                  updateParagraph={updateParagraph}
                />
              );
            },
            ul: ({ children }) => {
              return (
                <MdList children={children} updateParagraph={updateParagraph} />
              );
            },
            code: ({ children, inline, className }) => {
              if (!inline) {
                if (className && className.includes("language-ai")) {
                  return (
                    <AIBlock
                      children={children}
                      currentPrompt={currentTarget}
                    />
                  );
                }
              }
              return <code className={className}>{children}</code>;
            },
          },
        }}
      />
    </Stack>
  );
};

export default MarkdownEditor;
