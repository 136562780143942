import React, { useRef, useState } from "react";
import MarkdownEditor from "components/markdown-editor/MarkdownEditor";
import { MenuList, Stack } from "@mui/material";
import { colors } from "colors";
import useIsMobile from "hooks/react-lib/generic-ui/useIsMobile";
import LabelledOutline from "components/react-lib/generic-ui/LabelledOutline";
import CreateMDDocumentButton from "components/markdown-editor/CreateMDDocumentButton";
import ActiveDocument from "components/markdown-editor/ActiveDocument";
import DocumentList from "components/markdown-editor/DocumentList";
import MainMenu from "components/menus/MainMenu";
import CurrentBotHeader from "components/bot/CurrentBotHeader";
import { useAuth } from "context/react-lib/auth/AuthProvider";
import ChargeAccountDisplay from "components/react-lib/stripe-credit-payments/ChargeAccountDisplay";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, orderBy, query, where } from "firebase/firestore";
import { db } from "firebaseApp";
import { COLLECTIONS } from "collections";
import DocumentDrawerMenu from "components/menus/DocumentDrawerMenu";

const MarkdownEditorView = () => {
  const auth = useAuth();
  const uid = auth.currentUser.uid;

  const DOCUMENT_LIST_WIDTH = 300;
  const isMobile = useIsMobile();

  const [documentsSnap, loading, error] = useCollection(
    query(
      collection(db, COLLECTIONS.mdDocuments),
      where("uid", "==", uid),
      orderBy("createdAt", "desc")
    )
  );

  return (
    <Stack
      sx={{
        width: "100vw",
        height: "100vh",
        backgroundColor: colors.black,
        zIndex: 0,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        height="64px"
      >
        <Stack direction="row" spacing={1}>
          <MainMenu />
          <CurrentBotHeader />
        </Stack>

        <Stack direction="row" spacing={0}>
          <ChargeAccountDisplay
            negativeCreditsColor={colors.pink}
            uid={uid}
            nameProps={{
              color: colors.white,
            }}
            amountProps={{
              color: colors.white,
              fontWeight: "bold",
            }}
            containerProps={{
              p: 2,
            }}
          />
          {isMobile && (
            <DocumentDrawerMenu
              documentsSnap={documentsSnap}
              loading={loading}
              error={error}
            />
          )}
        </Stack>
      </Stack>
      <Stack
        sx={{
          height: "calc(100vh - 64px)",
          width: "100%",
          zIndex: 0,
        }}
        direction="row"
      >
        {!isMobile && (
          <Stack p={1}>
            <CreateMDDocumentButton color="white" />
            <MenuList
              sx={{
                height: "95%",
                width: `${DOCUMENT_LIST_WIDTH}px`,
                overflowY: "auto",
                overflowX: "none",
                boxSizing: "border-box",
              }}
            >
              <DocumentList
                documentsSnap={documentsSnap}
                loading={loading}
                error={error}
              />
            </MenuList>
          </Stack>
        )}
        <Stack
          sx={{
            width: `calc(100% - ${isMobile ? 0 : DOCUMENT_LIST_WIDTH}px)`,
            height: "100%",
          }}
        >
          <ActiveDocument />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default MarkdownEditorView;
