import Selector from "components/react-lib/generic-ui/Selector";
import React from "react";
import BotAvatar from "./BotAvatar";

export default function BotSelector({bots, selectedBot, setSelectedBot}) {
  
  return (
    <Selector
      label="Bot"
      value={selectedBot}
      setValue={setSelectedBot}
      options={bots}
      valueField="id"
      labelField="name"
      getIcon={(bot) => <BotAvatar botId={bot.id} />}
    />
  );

}
