// @lib-generic-ui
import React, { createContext, useContext, useRef } from 'react';

const GlobalRefContext = createContext({});

export const GlobalRefProvider = ({ children }) => {
  const refMap = useRef({});

  return (
    <GlobalRefContext.Provider value={refMap.current}>
      {children}
    </GlobalRefContext.Provider>
  );
};

export const useGlobalRefs = () => useContext(GlobalRefContext);