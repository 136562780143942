import {
  Stack,
} from "@mui/material";
import React from "react";
import { doc } from "firebase/firestore";
import { db } from "firebaseApp";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { signOut } from "firebase/auth";
import { useAuth } from "context/react-lib/auth/AuthProvider";
import RechargeForm from "components/react-lib/stripe-credit-payments/RechargeForm";
import LabelledOutline from "components/react-lib/generic-ui/LabelledOutline";
import { colors } from "colors";
import Loader from "../react-lib/generic-ui/Loader";
import ChargeAccountSelector from "components/react-lib/stripe-credit-payments/ChargeAccountSelector";
import ReduxModal from "components/react-lib/generic-ui/modals/ReduxModal";

export default function CreditAccountsModal() {
  
  const auth = useAuth();
  const uid = auth.currentUser?.uid;

  const [profile, loadingProfile, profileError] = useDocumentData(
    doc(db, "userPublicProfiles", uid)
  );

  if (profileError) {
    console.log(profileError);
  }

  const renderContent = () => {
    return (
      <Stack spacing={2}>
        {loadingProfile || !profile ? (
          <Loader message="Loding profile" />
        ) : (
          <Stack spacing={2} pt={1}>
            <LabelledOutline
              label="Select active credit account"
              rawLabelColor={colors.black}
              rawBorderColor={colors.black}
            >
              <ChargeAccountSelector uid={uid} />
            </LabelledOutline>
            <LabelledOutline
              label={"Add credits to a credit account"}
              rawLabelColor={colors.black}
              rawBorderColor={colors.black}
            >
              <RechargeForm
                uid={uid}
                headerText=""
                amountButtonColor="pink"
                rechargeButtonColor="black"
                renderLoader={() => (
                  <Loader
                    message="Loading"
                    progressProps={{
                      color: "black",
                    }}
                  />
                )}
              />
            </LabelledOutline>
          </Stack>
        )}
      </Stack>
    );
  };

  return (
    <ReduxModal
      name="creditAccounts"
      title="Credit Accounts"
      renderContent={renderContent}
    />
  );
}
