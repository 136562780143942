import { BOOLEAN_FIELD, EXTERNAL_ID_FIELD, STRING_FIELD, TIMESTAMP_FIELD } from "utils/react-lib/generic-ui/firestoreFields";

export const conversationSchema =  {
  
  "bots/{botId}/conversations/{conversationId}": (buildData) => ({
    _fieldOrder: ["name", "content"],
    botId: EXTERNAL_ID_FIELD("Bot Id", buildData),
    createdAt: TIMESTAMP_FIELD("Created At"),
    isDefault: BOOLEAN_FIELD("Is Default"),
    subject: STRING_FIELD("Name"),
    uid: EXTERNAL_ID_FIELD("User Id", buildData),
  }),

}