// @lib-generic-ui
import React from "react";
import { IconButton } from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CloseIcon from "@mui/icons-material/Close";

function ImageSelectorButton({ image, setImage, ...props }) {
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setImage(URL.createObjectURL(file));
    }
  };

  if(image) {
    return (
      <IconButton
        onClick={() => setImage("")}
        {...props}
      >
        <CloseIcon />
      </IconButton>
    )
  }
  
  return (
    <IconButton color="primary" component="label" {...props}>
      <AddPhotoAlternateIcon />
      <input type="file" onChange={handleImageChange} hidden />
    </IconButton>
  );
}

export default ImageSelectorButton;
