import React from "react";
import { useSelector } from "react-redux";
import BotAvatar from "./BotAvatar";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { colors } from "colors";
import useIsMobile from "hooks/react-lib/generic-ui/useIsMobile";

export default function CurrentBotHeader() {

  const isMobile = useIsMobile();
  const botState = useSelector((state) => state.bot);

  if(botState.loading) {
    return (
      <CircularProgress
        sx={{
          color: colors.white,
        }}
      />
    );
  }

  if (!botState.current) return null;

  const bot = botState.idMap[botState.current];

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <BotAvatar botId={bot.id} />
      <Stack>
        <Typography 
          data-testid="bot-header-name"
          fontWeight="bold" 
          color={colors.white}
          fontSize={isMobile ? 10 : 18}
        >
          {bot.name}
        </Typography>
        <Typography 
          variant="caption" 
          color={colors.white}
          fontSize={isMobile ? 10 : 12}

        >
          {bot.model}
        </Typography>
      </Stack>
    </Stack>
  );
}
