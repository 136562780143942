import { configureStore } from "@reduxjs/toolkit";
import chatReduer from "./chat";
import userReducer from "store/user";
import botReducer from "store/bot";
import createBotReducer from "store/createBot";
import editorReducer from "store/editor";
import shareReducer from "store/share";
import modalsReducer from "store/react-lib/generic-ui/modals";
import popupReducer from "store/popup";

const store = configureStore({
  reducer: {
    chat: chatReduer,
    user: userReducer,
    bot: botReducer,
    createBot: createBotReducer,
    editor: editorReducer,
    share: shareReducer,
    modals: modalsReducer,
    popup: popupReducer,
  }
});

export default store;