// @lib-generic-ui
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useGlobalRefs } from "context/react-lib/generic-ui/GlobalRefContext";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalsActions } from "store/react-lib/generic-ui/modals";

export default function ReduxModal({
  name, 
  title, 
  closeCallback = () => {}, 
  renderContent = () => null, 
  ...props
}) {

  const dispatch = useDispatch();
  const state = useSelector((state) => state.modals[name]);
  const globalRefs = useGlobalRefs();

  const handleClose = () => {
    closeCallback();
    if(globalRefs.modalFocusRef.current) globalRefs.modalFocusRef.current.focus();
    dispatch(modalsActions.closeModal(name));
  }

  const open = Boolean(state?.open);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      {...props}
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        {renderContent()}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
