// @lib-generic-ui
import {
  EXTERNAL_ID_TYPE,
  IMAGE_TYPE,
  INT_TYPE,
  NUMBER_TYPE,
  STRING_TYPE,
  TIMESTAMP_TYPE,
  AVATAR_TYPE,
  STRING_OPTIONS_TYPE,
  BOOLEAN_TYPE,
} from "utils/react-lib/generic-ui/firestoreTypes";
export const EQ_OP = "==";
export const LEQ_OP = "<=";
export const LE_OP = "<";
export const GEQ_OP = ">=";
export const GE_OP = ">";
export const NEQ_OP = "!=";

export const ALL_OP = [EQ_OP, LEQ_OP, LE_OP, GEQ_OP, GE_OP, NEQ_OP];

export const OPERATORS = {
  "==": (x, y) => x === y,
  "<=": (x, y) => x <= y,
  "<": (x, y) => x < y,
  ">=": (x, y) => x >= y,
  ">": (x, y) => x > y,
  "!=": (x, y) => x !== y,
};

const isInteger = (value) => Number.isInteger(Number(value));

function isNumber(str) {
  const num = Number(str);
  return !isNaN(num);
}

function isBoolean(value) {
  return typeof value === 'boolean';
}

function parseBoolean(value) {
  if (typeof value === 'string') {
    return value.toLowerCase() === 'true';
  }
  return Boolean(value);
}


export const BOOLEAN_FIELD = (label) => ({
  type: BOOLEAN_TYPE,
  defaultValue: "",
  label,
  isValid: isBoolean,
  editor: null,
  renderer: null,
  operators: ALL_OP,
  fromStr: parseBoolean,
});

export const INTEGER_FIELD = (label) => ({
  type: INT_TYPE,
  defaultValue: "",
  label: label,
  isValid: isInteger,
  editor: null,
  renderer: null,
  operators: ALL_OP,
  fromStr: parseInt,
});

export const NUMBER_FIELD = (label) => ({
  type: NUMBER_TYPE,
  defaultValue: "",
  label: label,
  isValid: isNumber,
  editor: null,
  renderer: null,
  operators: ALL_OP,
  fromStr: parseFloat,
});

export const STRING_FIELD = (label, defaultEditorProps = {}) => ({
  type: STRING_TYPE,
  defaultValue: "",
  label,
  isValid: (value) => Boolean(value),
  editor: null,
  renderer: null,
  operators: ALL_OP,
  fromStr: (value) => value,
  defaultEditorProps,
});

export const STRING_OPTIONS = (label, options) => ({
  type: STRING_OPTIONS_TYPE,
  defaultValue: options[0].value,
  label,
  options,
  isValid: (value) => options.map((opt) => opt.value).includes(value),
  editor: null,
  renderer: null,
  operators: [EQ_OP],
  fromStr: (value) => value,
});

export const IMAGE_FIELD = (label, buildData) => ({
  type: IMAGE_TYPE,
  defaultValue: "",
  label: label,
  isValid: () => true,
  editor: null,
  renderer: null,
  operators: [EQ_OP],
  fromStr: (value) => value,
  bucket: buildData.bucket,
});

export const AVATAR_FIELD = (label, buildData) => ({
  type: AVATAR_TYPE,
  defaultValue: "",
  label: label,
  isValid: () => true,
  editor: null,
  renderer: null,
  operators: [EQ_OP],
  fromStr: (value) => value,
  bucket: buildData.bucket,
})

export const TIMESTAMP_FIELD = (label) => ({
  type: TIMESTAMP_TYPE,
  defaultValue: null,
  label: label,
  isValid: (value) => Boolean(value),
  editor: null,
  renderer: null,
  operators: [LEQ_OP, LE_OP, GEQ_OP, GE_OP],
  defaultOperator: LEQ_OP,
  fromStr: (dateInfo) => dateInfo.timestamp,
});

export const EXTERNAL_ID_FIELD = (label, buildData) => ({
  type: EXTERNAL_ID_TYPE,
  defaultValue: "",
  label: label,
  isValid: (value) => Boolean(value),
  editor: null,
  renderer: null,
  selectorData: buildData.selectorData, // { query, displayField }
  operators: [EQ_OP],
  fromStr: (value) => value,
});