import { CircularProgress, IconButton } from "@mui/material";
import React, { useState } from "react";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { httpsCallable } from "firebase/functions";
import { functions } from "firebaseApp";
import { useDispatch } from "react-redux";
import { popupActions } from "store/popup";


export default function GenerateAudioButton({ message, processing }) {

  const dispatch = useDispatch();
  const [processingAudio, setProcessingAudio] = useState(Boolean(message.processingAudio));
  
  const handleListen = async () => {
    try {
      setProcessingAudio(true);
      const res = await httpsCallable(
        functions,
        "convertMessageToAudio"
      )({
        messageId: message.id,
        conversationId: message.conversationId,
        botId: message.botId,
      });
      const resData = res.data;
      if(!resData.success) {
        console.log(resData);
        dispatch(popupActions.showError(resData.error));
      }
    } catch (error) {
      setProcessingAudio(false);
      console.log(error);
    } finally {
      setProcessingAudio(false);
    }
  };

  if(message.type === "image" || message.type === "pending") return null;

  if(Boolean(message.audioUrl)) return null;
  
  if(processingAudio) {
    return (
      <CircularProgress size={24} color="white" />
    )
  }
  
  return (
    <IconButton
      onClick={handleListen}
      color="white"
    >
      <VolumeUpIcon />
    </IconButton>
  );

}
