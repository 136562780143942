import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material";
import { colors } from "colors";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { botActions } from "store/bot";
import ConversationMenu from "./ConversationMenu";
import Loader from "components/react-lib/generic-ui/Loader";

export default function ConversationList({ conversations, loading, error }) {
  const botState = useSelector((state) => state.bot);

  const dispatch = useDispatch();

  if (error) {
    console.log(error);
    return null;
  }

  const renderConversations = () => {
    if (!conversations) return null;
    if (conversations.docs.length === 0) {
      return (
        <ListItem>
          <ListItemText
            sx={{
              color: colors.white,
            }}
          >
            No conversations.
          </ListItemText>
        </ListItem>
      );
    }
    const conversationDocs = conversations.docs.map((snap) => ({
      id: snap.id,
      ...snap.data(),
    }));
    return conversationDocs.map((conversation, index) => {
      return (
        <ListItem
          key={conversation.id}
          secondaryAction={
            <ConversationMenu
              previousConversation={
                index === 0 ? null : conversationDocs[index - 1]
              }
              nextConversation={
                index + 1 >= conversationDocs.length
                  ? null
                  : conversationDocs[index + 1]
              }
              conversation={conversation}
              color="white"
            />
          }
          sx={{
            borderRadius: "4px",
            backgroundColor:
              conversation.id === botState.activeConversation?.id
                ? colors.drakGray
                : colors.black,
          }}
        >
          <ListItemButton
            onClick={() =>
              dispatch(botActions.setActiveConversation(conversation))
            }
          >
            <ListItemText
              sx={{
                color: colors.white,
              }}
              primary={<Typography>{conversation.subject}</Typography>}
              secondary={
                <Typography variant="caption">
                  {new Date(conversation.createdAt).toLocaleString()}
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>
      );
    });
  };

  return (
    <List dense disablePadding>
      {loading && (
        <>
          {Array.from(Array(3)).map((x, index) => (
            <ListItem
              key={`conversation-skeleton-${index}`}
            >
              <ListItemText
                primary={<Skeleton variant="text" sx={{bgcolor: colors.drakGray}} />}
                secondary={<Skeleton variant="text" sx={{bgcolor: colors.drakGray}} />}
              />
            </ListItem>
          ))}
        </>
      )}
      {renderConversations()}
    </List>
  );
}
