import React, { useEffect } from "react";
import { MenuList, Stack, Typography } from "@mui/material";
import { colors } from "colors";
import LabelledOutline from "components/react-lib/generic-ui/LabelledOutline";
import ConversationList from "components/chat/ConversationList";
import Conversation from "components/chat/Conversation";
import NewConversationButton from "components/chat/NewConversationButton";
import MainMenu from "components/menus/MainMenu";
import { useAuth } from "context/react-lib/auth/AuthProvider";
import ChargeAccountDisplay from "components/react-lib/stripe-credit-payments/ChargeAccountDisplay";
import CurrentBotHeader from "components/bot/CurrentBotHeader";
import { collection, orderBy, query, where } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { db } from "firebaseApp";
import { useDispatch, useSelector } from "react-redux";
import { botActions } from "store/bot";
import useIsMobile from "hooks/react-lib/generic-ui/useIsMobile";
import { COLLECTIONS } from "collections";
import ConversationDrawerMenu from "components/menus/ConversationDrawerMenu";
import ChatInput from "components/chat/ChatInput";
import PricingModal from "components/pricing/PricingModal";

export default function ChatView() {
  const CONVERSATION_LIST_WIDTH = 300;

  const dispatch = useDispatch();

  const auth = useAuth();
  const uid = auth.currentUser.uid;

  const isMobile = useIsMobile();

  const botState = useSelector((state) => state.bot);
  const bot = botState.idMap[botState.current];

  const [conversations, loading, error] = useCollection(
    botState.current
      ? query(
          collection(
            db,
            COLLECTIONS.bots,
            botState.current,
            COLLECTIONS.conversations
          ),
          where("uid", "==", uid),
          orderBy("createdAt", "desc")
        )
      : null
  );

  // useEffect(() => {
  //   if (conversations) {
  //     if (conversations.docs.length === 0) {
  //       dispatch(botActions.setActiveConversation(null));
  //     } else {
  //       const mostRecentConversationSnap = conversations.docs[0];
  //       dispatch(
  //         botActions.setActiveConversation({
  //           id: mostRecentConversationSnap.id,
  //           ...mostRecentConversationSnap.data(),
  //         })
  //       );
  //     }
  //   }
  // }, [conversations]);

  const renderWithActiveCovnersation = () => {
    return (
      <>
        {!isMobile && (
          <Stack
            p={1}
          >
            <NewConversationButton color="white" />
            <MenuList
              sx={{
                height: "95%", // A bit hacky, we should fix this
                width: `${CONVERSATION_LIST_WIDTH}px`,
                overflowY: "auto",
                overflowX: "none",
                boxSizing: "border-box",
              }}
            >
              <ConversationList
                conversations={conversations}
                loading={loading}
                error={error}
              />
            </MenuList>
          </Stack>
        )}
        <Stack
          sx={{
            width: `calc(100% - ${isMobile ? 0 : CONVERSATION_LIST_WIDTH}px)`,
            height: "100%",
            overflow: "auto",
          }}
        >
          <Conversation />
        </Stack>
      </>
    );
  };

  const renderWithoutActiveConversation = () => {
    return (
      <Stack
      spacing={1}
      alignItems="center"
      justifyContent="center"
      sx={{
        boxSizing: "border-box",
        width: "100%",
        height: "100%",
      }}
      px={3}
      py={2}
    >
      <Typography
        fontFamily="'Anton', serif"
        variant="h6"
        sx={{
          color: colors.white,
        }}
      >
        {bot?.type === "image" ? (
          "What can I draw for you ?"
        ) : (
          "What do you want to chat about ?"
        )}
      </Typography>
      <ChatInput
        sx={{
          zIndex: 3,
          width: "100%",
        }}
      />
    </Stack>
    );
  };

  const renderBottomContent = () => {
    return renderWithActiveCovnersation();
    if (botState.activeConversation) {
      return renderWithActiveCovnersation();
    }
    return renderWithoutActiveConversation();
  };

  return (
    <Stack
      sx={{
        width: "100vw",
        height: "100vh",
        backgroundColor: colors.black,
        zIndex: 0,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        height="64px"
      >
        <Stack direction="row" spacing={1}>
          <MainMenu />
          <CurrentBotHeader />
        </Stack>
        <Stack direction="row" spacing={0}>
          <ChargeAccountDisplay
            negativeCreditsColor={colors.pink}
            uid={uid}
            nameProps={{
              color: colors.white,
            }}
            amountProps={{
              color: colors.white,
              fontWeight: "bold",
            }}
            containerProps={{
              p: 2,
            }}
          />
          {isMobile && (
            <ConversationDrawerMenu
              conversations={conversations}
              loading={loading}
              error={error}
            />
          )}
        </Stack>
      </Stack>
      <Stack
        sx={{
          height: "calc(100vh - 64px)",
          width: "100%",
          zIndex: 0,
        }}
        direction="row"
      >
        {renderBottomContent()}
      </Stack>
    </Stack>
  );
}
