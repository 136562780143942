// @lib-generic-ui
import React, { useEffect, useRef, useState } from "react";
import { TextField } from "@mui/material";

export default function TextInput({ value, setValue, inputDataTestId, ...props }) {

  const textFieldRef = useRef(null);

  const handleChange = (event) => {
    setValue(event.target.value);
  }

  const [isFocused, setIsFocused] = useState(false);

  const handleWheel = (event) => {
    if (isFocused) {
      event.preventDefault();
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    if(textFieldRef.current) {
      console.log("EFFECT");
      textFieldRef.current.addEventListener("wheel", handleWheel, { passive: false });
    }
  }, []);

  return (
    <TextField
      {...props}
      ref={textFieldRef}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        "data-testid": inputDataTestId,
      }}
      value={value}
      onChange={handleChange}
      onFocus={handleFocus} // Set focus state
      onBlur={handleBlur} // Remove focus state
      //onWheel={handleWheel} // Prevent scroll if focused
    />
  );
}
