import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import ToolButton from "components/tools/ToolButton";
import Diff from "./Diff";

export default function ContentEditorModal({
  open,
  onClose,
  paragraphContent,
  updateParagraph,
}) {

  const tools = useSelector((state) => state.editor.tools);
  const [newContent, setNewContent] = useState(paragraphContent);


  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>Paragraph Editor</DialogTitle>
      <DialogContent>
        <Diff original={paragraphContent} modified={newContent} />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => updateParagraph(paragraphContent, newContent)}
        >
          Save
        </Button>
        {tools.map((tool) => (
          <ToolButton
            key={tool.id} 
            tool={tool} 
            content={paragraphContent}
            setResult={setNewContent}
          />
        ))}
      </DialogActions>
    </Dialog>
  );
}
