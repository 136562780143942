import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  messages: [],
  liveMessage: [],
  answerIndex: 0,
  answer: [],
  listening: false,
  serverAnswer: null,
  liveBotId: null,
  error: "",
  isScrollDown: true,
  botPrompts: {},
  botMessages: {},
  botError: {},
  typing: false,
  botProcessing: {},
  botAudio: {},
  botAttachments: {},
  prompt: "",
};

const chatSlice = createSlice({
  name: "chatSlice",
  initialState: initialState,
  reducers: {
    resetAnswer(state) {
      state.answer = [];
    },
    setPrompt(state, action) {
      state.prompt = action.payload;
    },
    addBotAttachment(state, action) {
      const { botId, attachment } = action.payload;
      const previous = state.botAttachments[botId] || [];
      state.botAttachments[botId] = [...previous, attachment];
    },
    clearBotAudio(state, action) {
      const {botId} = action.payload;
      state.botAudio[botId] = {recording: false, audioUrl: ""};
    },
    setBotAudio(state, action) {
      const {botId, audioUrl, recording} = action.payload;
      console.log(audioUrl, recording);
      state.botAudio[botId] = {audioUrl, recording};
    },
    setBotPrompt(state, action) {
      const {botId, prompt} = action.payload;
      state.botPrompts[botId] = prompt;
    },
    setBotProcessing(state, action) {
      const {botId, processing} = action.payload;
      state.botProcessing[botId] = processing;
    },
    syncLiveMessage(state, action) {
      state.liveMessage = action.payload;
    },
    listenToAnswer(state, action) {
      state.error = "";
      state.liveMessage = [];
      state.answer = [];
      state.answerIndex = 0;
      state.listening = true;
      state.liveBotId = action.payload;
    },
    finishListening(state) {
      state.listening = false;
      state.answer = [];
      state.answerIndex = 0;
      state.liveMessage = [];
      state.liveBotId = null;
    },
    updateAnswer(state) {
      state.answer = [...state.answer, state.liveMessage[state.answerIndex]];
      state.answerIndex += 1;
    },
    // Message actions
    initializeMessagesBotMessages(state, action) {
      const { botId, messages } = action.payload;
      state.botMessages[botId] = [...messages];
    },
    initializeMessages(state, action) {
      state.isScrollDown = true;
      state.messages = action.payload;
    },
    addBotMessage(state, action) {
      state.isScrollDown = true;
      const { botId, message } = action.payload;
      state.botMessages[botId] = [...state.botMessages[botId], message];
    },
    addMessage(state, action) {
      state.isScrollDown = true;
      state.messages = [...state.messages, action.payload];
    },
    prependBotMessages(state, action) {
      const { botId, messages } = action.payload;
      state.isScrollDown = false;
      state.botMessages[botId] = [...messages, ...state.botMessages[botId]];
    },
    prependMessages(state, action) {
      state.isScrollDown = false;
      state.messages = [...action.payload, ...state.messages];
    },
    setBotServerAnswer(state, action) {
      const { botId, message } = action.payload;
      state.isScrollDown = true;
      state.botMessages[botId] = [...state.botMessages[botId], message];
      state.listening = false;
      state.answer = [];
      state.answerIndex = 0;
      state.liveMessage = [];
      state.liveBotId = null;
    },
    setServerAnswer(state, action) {
      state.isScrollDown = true;
      state.messages = [...state.messages, action.payload];
      state.listening = false;
      state.answer = [];
      state.answerIndex = 0;
      state.liveMessage = [];
      state.liveBotId = null;
    },
    setBotError(state, action) {
      const { error, botId } = action.payload;
      state.isScrollDown = true;
      state.botError[botId] = error;
      state.listening = false;
      state.answer = [];
      state.answerIndex = 0;
      state.liveMessage = [];
      state.liveBotId = false;
    },
    clearBotError(state, action) {
      const botId = action.payload;
      state.botError[botId] = "";
    },
    setError(state, action) {
      state.isScrollDown = true;
      state.error = action.payload;
      state.messages = state.messages.slice(0, -1);
      state.listening = false;
      state.answer = [];
      state.answerIndex = 0;
      state.liveMessage = [];
      state.liveBotId = false;
    },
    setTyping(state, action) {
      state.typing = action.payload;
    }
  },
});

export const chatActions = chatSlice.actions;

export default chatSlice.reducer;
