// @lib-stripe-credit-payments
import { Stack } from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "firebaseApp";
import React, { useState } from "react";
import AlertMessage from "../generic-ui/AlertMessage";
import AccountSelector from "./AccountSelector";
import { useDocumentData } from "react-firebase-hooks/firestore";
import Loader from "../generic-ui/Loader";

export default function ChargeAccountSelector({ uid }) {
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState();

  const [publicProfileData, loading, loadError] = useDocumentData(
    doc(db, "userPublicProfiles", uid)
  );

  const handleSelectAccount = async (chargeAccountId) => {
    try {
      setError();
      setProcessing(true);
      await updateDoc(doc(db, "userPublicProfiles", uid), {
        defaultChargeAccount: chargeAccountId,
      });
    } catch (error) {
      console.log(error);
      setError("Couldn't change charge account. Try againt later.");
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Stack spacing={1}>
      {error && <AlertMessage>{error}</AlertMessage>}
      {loading && <Loader message="Loading accounts" />}
      {!loading &&
      (
        <AccountSelector
          uid={uid}
          selectAccountCallback={handleSelectAccount}
          processing={processing}
          defaultAccountId={publicProfileData?.defaultChargeAccount}
        />
      )}
    </Stack>
  );
}
