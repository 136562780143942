// @lib-generic-ui
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { popupActions } from "store/popup";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, IconButton, Snackbar } from "@mui/material";

export default function PopupMessage() {
  
  const dispatch = useDispatch();
  const popupState = useSelector((state) => state.popup);

  const handleClose = () => {
    dispatch(popupActions.close());
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={Boolean(popupState.message)}
      autoHideDuration={popupState.duration}
      onClose={handleClose}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    >
      <Alert
        onClose={handleClose}
        severity={popupState.severity}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {popupState.message}
      </Alert>
    </Snackbar>
  );
}
