import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "firebaseApp";
import { colors } from "colors";
import ConfirmModal from "components/react-lib/generic-ui/ConfirmModal";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { botActions } from "store/bot";
import { COLLECTIONS } from "collections";
import EditDocumentMenuItem from "components/react-lib/generic-ui/firestore/EditDocumentMenuItem";

export default function ConversationMenu({ 
  previousConversation,
  nextConversation,
  conversation,
  color = colors.white,
}) {

  const conversationRef = doc(
    db,
    COLLECTIONS.bots,
    conversation.botId,
    COLLECTIONS.conversations,
    conversation.id,
  );

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [processing, setProcessing] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  const open = Boolean(anchorEl);

  const activeConversation = useSelector((state) => state.bot.activeConversation);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    try {
      setProcessing(true);
      await deleteDoc(conversationRef);
      handleClose();
      console.log(previousConversation);
      console.log(nextConversation);
      if(conversation.id === activeConversation.id) {
        if(previousConversation) {
          dispatch(botActions.setActiveConversation(previousConversation));
        } else if(nextConversation) {
          dispatch(botActions.setActiveConversation(nextConversation));        
        } else {
          dispatch(botActions.setActiveConversation(null));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setProcessing(false);
    }
  };

  if(conversation.isDefault) {
    return null;
  }

  return (
    <div>
      <ConfirmModal
        open={deleting}
        setOpen={setDeleting}
        title="Confirm"
        message="Are you sure to delete this conversation?"
        confirmAction={handleDelete}
        cancelAction={handleClose}
      />
      <IconButton onClick={handleClick} color={color} disabled={processing}>
        {processing ? (
          <CircularProgress size={24} sx={{ color }} />
        ) : (
          <MoreVertIcon />
        )}
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <EditDocumentMenuItem
          label="Rename"
          documentRef={conversationRef}
          iconProps={{
            color: "black",
          }}
          fieldsToEdit={["subject"]}
          closeMenu={handleClose}
        />
        <MenuItem onClick={() => setDeleting(true)}>
          <ListItemIcon>
            <DeleteIcon color="black" fontSize="small" />
          </ListItemIcon>
          <ListItemText color="black">Delete</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}
