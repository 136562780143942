// @lib-generic-ui
import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import DefaultFieldEditor from "./DefaultFieldEditor";
import { updateDoc, addDoc } from "firebase/firestore";
import ActionButton from "../ActionButton";
import { useDocument } from "react-firebase-hooks/firestore";
import Loader from "../Loader";
import _ from "lodash";
import { getSchema } from "utils/react-lib/generic-ui/firebaseUtils";

export default function EditDocumentForm({
  documentRef,
  schemaBuildData = {},
  saveCallback = () => {},
  errorCallback = () => {},
  fieldsToEdit = null,
  isCreate = false,
  createExtraData = {},
}) {
  const schema = getSchema(documentRef, schemaBuildData);
  const fieldOrder = schema._fieldOrder;

  fieldsToEdit = fieldsToEdit || fieldOrder;

  const [saving, setSaving] = useState();
  const [state, setState] = useState();
  const [initialState, setInitialState] = useState();

  const [docSnapshot, loading, error] = useDocument(
    isCreate ? null : documentRef
  );

  useEffect(() => {
    let doc = {};

    if (!isCreate && docSnapshot) {
      doc = {
        id: documentRef.id,
        ...docSnapshot.data(),
      };
    }

    fieldsToEdit.forEach((field) => {
      if (!doc.hasOwnProperty(field)) {
        doc[field] = schema[field].defaultValue;
      }
    });

    setState(doc);
    setInitialState(doc);
  }, [docSnapshot]);

  if (error) {
    console.log(error);
  }

  if (loading || !state) {
    return <Loader message="Loading" />;
  }

  const setFieldValue = (field) => (value) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  const renderFieldEditor = (field, index) => {
    const editor = schema[field].editor;
    if (editor) {
      return editor(state[field], setFieldValue);
    }
    return (
      <DefaultFieldEditor
        key={`${field}-${index}`}
        value={state[field]}
        setValue={setFieldValue(field)}
        field={field}
        fieldSchema={schema[field]}
        processing={saving}
      />
    );
  };

  const handleSave = async () => {
    try {
      setSaving(true);
      if (isCreate) {
        await addDoc(documentRef, {...createExtraData, ...state});
      } else {
        await updateDoc(documentRef, state);
      }
      saveCallback();
    } catch (error) {
      console.log(error);
      errorCallback(error);
    } finally {
      setSaving(false);
    }
  };

  const canSave = () => {
    if (_.isEqual(state, initialState)) {
      return false;
    }
    for (const field of fieldsToEdit) {
      if (!schema[field].isValid(state[field])) {
        return false;
      }
    }
    return true;
  };

  fieldsToEdit = fieldsToEdit || fieldOrder;

  return (
    <Stack spacing={2}>
      {fieldsToEdit.map((field, index) => renderFieldEditor(field, index))}
      <ActionButton
        data-testclass="edit-document-form-save-button"
        processing={saving}
        variant="contained"
        onClick={handleSave}
        disabled={!canSave()}
      >
        Save
      </ActionButton>
    </Stack>
  );
}