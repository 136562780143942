// lib-firebase-auth
import { Button } from "@mui/material";
import { signOut } from "firebase/auth";
import { auth } from "firebaseApp";
import React, { useState } from "react";
import ConfirmModal from "../generic-ui/ConfirmModal";

export default function LogoutButton({ ...props }) {

  const [open, setOpen] = useState(false);

  const logout = async () => {
    await signOut(auth);
  };

  return (
    <>
      <ConfirmModal 
        open={open}
        setOpen={setOpen}
        title="Confirm Logout"
        message={"Are you sure to logout?"}
        confirmAction={logout}
      />
      <Button {...props} data-testid="logout-button" onClick={() => setOpen(true)}>
        Logout
      </Button>
    </>
  );
}
