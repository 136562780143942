import {
  Divider,
  Drawer,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { botActions } from "store/bot";
import { useDispatch, useSelector } from "react-redux";
import SettingsIcon from "@mui/icons-material/Settings";
import { createBotActions } from "store/createBot";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountModal from "../account/CreditAccountsModal";
import MenuIcon from "@mui/icons-material/Menu";
import GroupsIcon from "@mui/icons-material/Groups";
import GroupsModal from "components/groups/GroupsModal";
import CreateBotModal from "components/bot/CreateBotModal";
import BotAvatar from "components/bot/BotAvatar";
import EditProfileModal from "components/users/EditProfileModal";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import LogoutButton from "components/react-lib/auth/LogoutButton";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import ChatIcon from "@mui/icons-material/Chat";
import { useNavigate } from "react-router-dom";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import BugReportIcon from "@mui/icons-material/BugReport";
import EditDocumentMenuItem from "components/react-lib/generic-ui/firestore/EditDocumentMenuItem";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { colors } from "colors";
import { collection } from "firebase/firestore";
import { db } from "firebaseApp";
import { COLLECTIONS } from "collections";
import { useAuth } from "context/react-lib/auth/AuthProvider";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { modalsActions } from "store/react-lib/generic-ui/modals";
import { useGlobalRefs } from "context/react-lib/generic-ui/GlobalRefContext";
import PricingModal from "components/pricing/PricingModal";
import EditDocumentModal from "components/react-lib/generic-ui/modals/EditDocumentModal";
import { popupActions } from "store/popup";
import CreditAccountsModal from "../account/CreditAccountsModal";

export default function MainMenu() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const botState = useSelector((state) => state.bot);

  const [menuOpen, setMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [groupsModalOpen, setGroupsModalOpen] = useState(false);
  const [userModalOpen, setUserModalOpen] = useState(false);

  const bots = botState.bots;
  const auth = useAuth();
  const uid = auth.currentUser.uid;
  const currentBot = botState.current;

  const menuRef = useRef();
  const globalRefs = useGlobalRefs();
  globalRefs.modalFocusRef = menuRef;

  const handleSelectBot = (botId) => {
    dispatch(botActions.selectBot(botId));
    setMenuOpen(false);
  };

  const handleCreateBot = () => {
    dispatch(createBotActions.reset());
    setMenuOpen(false);
    setModalOpen(true);
  };

  const handleEdit = (event, bot) => {
    event.stopPropagation();
    dispatch(createBotActions.initializeFromBot(bot));
    setMenuOpen(false);
    setModalOpen(true);
  };

  const handleEditProfile = () => {
    setMenuOpen(false);
    setProfileModalOpen(true);
  };

  const handleEditGroups = () => {
    setMenuOpen(false);
    setGroupsModalOpen(true);
  };

  const formatName = (name) => {
    if (name.length > 24) {
      return `${name.substring(0, 24)}...`;
    }
    return name;
  };

  const handleOpenModal = (modalName) => () => {
    setMenuOpen(false);
    dispatch(modalsActions.openModal(modalName));
  };

  return (
    <>
      <IconButton
        onClick={() => setMenuOpen(true)}
        color="white"
        data-testid="main-menu-button"
        ref={menuRef}
      >
        <MenuIcon />
      </IconButton>

      <CreateBotModal open={modalOpen} setOpen={setModalOpen} />
      <CreditAccountsModal />
      <GroupsModal />
      <EditProfileModal />
      <PricingModal />

      <EditDocumentModal
        name="contact"
        title="Contact Us"
        documentRef={collection(db, COLLECTIONS.supportRequests)}
        fieldsToEdit={["type", "message"]}
        isCreate={true}
        createExtraData={{
          uid,
          createdAt: new Date().getTime(),
        }}
        saveCallback={() => dispatch(popupActions.showSuccess("Message sent! Thank you for your contact."))}
        errorCallback={() => dispatch(popupActions.showError("Couldn't send message. Try again later."))}
      />
      <Drawer
        data-testid="main-menu-drawer"
        open={menuOpen}
        anchor="left"
        onClose={() => setMenuOpen(false)}
      >
        <MenuList
          sx={{
            overflowY: "auto",
          }}
        >
          <MenuItem>
            <LogoutButton fullWidth variant="outlined" />
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => navigate("/")}
            data-testid="profile-menu-item"
          >
            <ListItemIcon>
              <QuestionAnswerIcon sx={{ color: colors.black }} />
            </ListItemIcon>
            <ListItemText>AI Chat</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => navigate("/mdEditor")}
            data-testid="profile-menu-item"
          >
            <ListItemIcon>
              <TextSnippetIcon sx={{ color: colors.black }} />
            </ListItemIcon>
            <ListItemText>AI Markdown Editor</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={handleOpenModal("contact")}
            data-testid="credit-accounts-menu-item"
          >
            <ListItemIcon>
              <ContactSupportIcon sx={{ color: colors.black }} />
            </ListItemIcon>
            <ListItemText>Contact Us</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={handleOpenModal("pricing")}
            data-testid="credit-accounts-menu-item"
          >
            <ListItemIcon>
              <MonetizationOnIcon sx={{ color: colors.black }} />
            </ListItemIcon>
            <ListItemText>Pricing</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={handleOpenModal("profile")}
            data-testid="profile-menu-item"
          >
            <ListItemIcon>
              <AccountCircleIcon sx={{ color: colors.black }} />
            </ListItemIcon>
            <ListItemText>Profile</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={handleOpenModal("creditAccounts")}
            data-testid="credit-accounts-menu-item"
          >
            <ListItemIcon>
              <AccountBalanceWalletIcon sx={{ color: colors.black }} />
            </ListItemIcon>
            <ListItemText>Credit Accounts</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleOpenModal("groups")} data-testid="groups-menu-item">
            <ListItemIcon>
              <GroupsIcon sx={{ color: colors.black }} />
            </ListItemIcon>
            <ListItemText>Groups</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={handleCreateBot}
            data-testid="create-bot-menu-item"
          >
            <ListItemIcon>
              <AddCircleOutlineIcon sx={{ color: colors.black }} />
            </ListItemIcon>
            <ListItemText>Create Bot</ListItemText>
          </MenuItem>
          <Divider />
          {bots.map((bot) => (
            <MenuItem
              data-testclass="main-menu-bot-item"
              key={bot.id}
              onClick={() => handleSelectBot(bot.id)}
              selected={bot.id === currentBot}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                width="100%"
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  pl={2}
                  sx={{
                    cursor: "pointer",
                  }}
                  spacing={1}
                >
                  <BotAvatar botId={bot.id} />
                  <Stack>
                    <Typography>{formatName(bot.name)}</Typography>
                    <Typography variant="caption">{bot.model}</Typography>
                  </Stack>
                </Stack>
                <IconButton onClick={(event) => handleEdit(event, bot)}>
                  <SettingsIcon />
                </IconButton>
              </Stack>
            </MenuItem>
          ))}
        </MenuList>
      </Drawer>
    </>
  );
}
